import * as CPF from './validators/cpf';
import CNPJ from 'cnpj';
import * as CreditCard from './validators/creditCard';

export const REQUIRED_FIELD_ERROR = 'Campo obrigatório';
export const EMAIL_FIELD_ERROR = 'E-mail inválido';
export const INVALID_PHONE_ERROR = 'Telefone inválido';
export const INVALID_CNPJ_ERROR = 'CNPJ Inválido';
export const INVALID_CPF_ERROR = 'CPF inválido';
export const INVALID_CEP_ERROR = 'CEP inválido';
export const INVALID_DOMAIN = 'O domínio pode conter somente letra(s) minúscula(s) sem acento(s) e/ou número(s)';
export const PASSWORD_MISMATCH_ERROR = 'As senhas não coincidem';
export const PASSWORD_MIN_LENGTH = 'Senha deve ter no mínimo 6 caracteres.'
export const INVALID_CREDIT_CARD_NUMBER_ERROR = 'Número do cartão inválido';
export const INVALID_CVV_CODE_ERROR = 'Código inválido';
export const UNCHECKED_TERM_OF_USE_ERROR = 'É necessário aceitar os termos de uso para continuar.';

export const CEP_LENGTH = 8;

export const CPF_LENGTH = 11;
export const CNPJ_LENGTH = 14;

export const isEmail = value => {
    return value && !/^[A-Z0-9._%+-]+@/i.test(value) 
        ? false 
        : true
};

export const isEmpty = value => value ? false : true;
export const isPhone = value => /[0-9]{10,11}/g.test(value);
export const isCEP = value  => /[0-9]{8}/g.test(value);
export const isValidDomain = value  => /^[a-z0-9]+$/.test(value);

export const syncValidation = (values) => {
    let errors = {};
    const { 
        email, 
        domain, 
        name,
        cpf,
        cnpj,
        phone,
        financialEmail,
        company,
        cep,
        address,
        addressNumber,
        district,
        state,
        city,
        creditCardNumber,
        creditCardName,
        creditCardCvv,
        monthValidityCreditCard,
        yearValidityCreditCard,
        billingCep,
        billingAddress,
        billingAddressNumber,
        billingDistrict,
        billingState,
        billingCity,
        password,
        confirmPassword,
        termOfUse,
    } = values;
    
    domainValidate(domain, errors);
    emailValidate(email, errors);
    financialEmailValidate(financialEmail, errors);
    phoneValidate(phone, errors);
    companyNameValidate(company, errors);
    termOfUseValidate(termOfUse, errors);
    
    nameValidate(name, errors);
    cnpjValidate(cnpj, errors);
    cpfValidate(cpf, errors);
    cepValidate(cep, errors);
    addressValidate(address, errors);
    addressNumberValidate(addressNumber, errors);
    districtValidate(district, errors);
    cityValidate(city, errors);
    stateValidate(state, errors);
    passwordMatchValidate(password, confirmPassword, errors);

    creditCardNumberValidate(creditCardNumber, errors);
    creditCardNameValidate(creditCardName, errors);
    creditCardCvvValidate(creditCardNumber, creditCardCvv, errors);
    monthValidityCreditCardValidate(monthValidityCreditCard, errors);
    yearValidityCreditCardValidate(yearValidityCreditCard, errors);

    billingCepValidate(billingCep, errors);
    billingAddressValidate(billingAddress, errors);
    billingAddressNumberValidate(billingAddressNumber, errors);
    billingDistrictValidate(billingDistrict, errors);
    billingCityValidate(billingCity, errors);
    billingStateValidate(billingState, errors);

    return errors;
};

export const termOfUseValidate = (isChecked, errors) => {
    if (!isChecked) {
        errors.termOfUse = UNCHECKED_TERM_OF_USE_ERROR;
    }

    return errors;
}

export const creditCardCvvValidate = (cardNumber, cvv, errors) => {
    const cardType = CreditCard.determineCardType(cardNumber, {
        allowPartial: true
    });

    if (!CreditCard.doesCvvMatchType(cvv, cardType)) {
        errors.creditCardCvv = INVALID_CVV_CODE_ERROR;
    }

    return errors;
};

export const monthValidityCreditCardValidate = (value, errors) => {
    if (!CreditCard.isValidExpiryMonth(value)) {
        errors.monthValidityCreditCard = REQUIRED_FIELD_ERROR;
    }

    return errors;
};

export const yearValidityCreditCardValidate = (value, errors) => {
    if (!CreditCard.isValidExpiryYear(value)) {
        errors.yearValidityCreditCard = REQUIRED_FIELD_ERROR;
    }

    return errors;
};

export const creditCardNameValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.creditCardName = REQUIRED_FIELD_ERROR;
    }

    return errors;
};

export const creditCardNumberValidate = (value, errors) => {
    const cardType = CreditCard.determineCardType(value, {
        allowPartial: true
    });

    const isValid = CreditCard.isValidCardNumber(value, cardType);
    if (!isValid) {
        errors.creditCardNumber = INVALID_CREDIT_CARD_NUMBER_ERROR;
    }
    return errors;
};

export const domainValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.domain = REQUIRED_FIELD_ERROR;
    }

    if (!isValidDomain(value)) { 
        errors.domain = INVALID_DOMAIN;
    }
};

export const emailValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.email = REQUIRED_FIELD_ERROR;
    } else if (!isEmail(value)) {
        errors.email = EMAIL_FIELD_ERROR;
    }
    return errors;
};

export const nameValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.name = REQUIRED_FIELD_ERROR;
    }
};

const financialEmailValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.financialEmail = REQUIRED_FIELD_ERROR;
    } else if (!isEmail(value)) {
        errors.financialEmail = EMAIL_FIELD_ERROR;
    }
    return errors;
};

export const phoneValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.phone = REQUIRED_FIELD_ERROR;
    } else if (!isPhone(value)) {
        errors.phone = INVALID_PHONE_ERROR;
    }
    return errors;
};

export const jobAreaValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.jobArea = REQUIRED_FIELD_ERROR;
    }
    return errors;
};

export const jobTypeValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.jobType = REQUIRED_FIELD_ERROR;
    }
    return errors;
};

export const companyNameValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.company = REQUIRED_FIELD_ERROR
    }
    return errors;
};

const cnpjValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.cnpj = REQUIRED_FIELD_ERROR;
    } else if (!CNPJ.validate(value)) {
        errors.cnpj = INVALID_CNPJ_ERROR;
    }

    return errors;
};

const cpfValidate = (value, errors) => {
    if (isEmpty(value)) {
        errors.cpf = REQUIRED_FIELD_ERROR;
    } else if (!CPF.isValid(value)) {
        errors.cpf = INVALID_CPF_ERROR;
    }
    return errors;
};

const cepValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.cep = REQUIRED_FIELD_ERROR
    } else if (!isCEP(value)) {
        errors.cep = INVALID_CEP_ERROR;
    }
    return errors;
};
 
const billingCepValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingCep = REQUIRED_FIELD_ERROR
    } else if (!isCEP(value)) {
        errors.billingCep = INVALID_CEP_ERROR;
    }
    return errors;
};

const addressValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.address = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const billingAddressValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingAddress = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const addressNumberValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.addressNumber = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const billingAddressNumberValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingAddressNumber = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const districtValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.district = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const billingDistrictValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingDistrict = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const cityValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.city = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const billingCityValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingCity = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const stateValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.state = REQUIRED_FIELD_ERROR
    }

    return errors;
};

const billingStateValidate = (value, errors) => {
    if(isEmpty(value)) {
        errors.billingState = REQUIRED_FIELD_ERROR
    }

    return errors;
};

export const passwordMatchValidate = (password, confirmPassword, errors) => {
    if (isEmpty(password) || password.length < 6) {
        errors.password = PASSWORD_MIN_LENGTH
    }
    if (isEmpty(confirmPassword) || confirmPassword.length < 6) {
        errors.confirmPassword = PASSWORD_MIN_LENGTH
    }
    if (password !== confirmPassword) {
        errors.confirmPassword = PASSWORD_MISMATCH_ERROR;
    }
    return errors;
};
