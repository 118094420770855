import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Divider from 'material-ui/Divider';
import { grey600 } from 'material-ui/styles/colors';
import { PAYMENT_BOLETO, PAYMENT_CREDIT_CARD } from '../helpers/paymentType';

const styles = {
    radioButton: {
        width: '12em',
        fontSize: '0.9em',
    },
    radioBox: { 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1em',
        marginBottom: '1em'
    }
};

const ClientTypeSwitch = (props) => {
    const { paymentType, onChange } = props;
    return (
        <Grid>
            <Row center='md'>
                <Col md={4}>
                    <Divider />
                    <RadioButtonGroup 
                        onChange={onChange} 
                        name="shipSpeed" 
                        style={styles.radioBox}
                        valueSelected={paymentType}>

                        <RadioButton
                            value={PAYMENT_CREDIT_CARD}
                            label="Cartão de crédito"
                            style={styles.radioButton}
                            labelStyle={{color:grey600}}
                        />
                        <RadioButton
                            value={PAYMENT_BOLETO}
                            label="Boleto"
                            style={styles.radioButton}
                            labelStyle={{color:grey600}}
                        />
                    </RadioButtonGroup>
                    <Divider />
                </Col>
            </Row>
        </Grid>
    )
}

ClientTypeSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    paymentType: PropTypes.string,
};

export default ClientTypeSwitch;
