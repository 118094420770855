
export const FIELD_MAX_LENGHT_CNPJ = 14;
export const FIELD_MAX_LENGHT_CPF = 11;
export const FIELD_MAX_LENGHT_CEP = 8;
export const FIELD_MAX_LENGHT_PHONE = 11;
export const FIELD_MAX_LENGTH_IE = 20;
export const FIELD_MAX_LENGTH_IM = 20;
export const FIELD_MAX_LENGTH_PASSWORD = 10;
export const FIELD_MAX_LENGTH_DOMAIN = 63;

export const onlyNumberNormalizer = (maxLength) => (value, previousValue) => {

    if (value && value.length > maxLength) {
        return;
    }

    if (value && /[^0-9]+/ig.test(value)) {
        return;
    }

    return value;
}

export const maxLengthNormalizer = (max) => (value, previousValue) => {
    if (value && value.length > max) {
        return;
    }

    return value;
}