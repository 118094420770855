import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import { Grid, Row, Col } from "react-flexbox-grid";
import FaCaretLeft from 'react-icons/lib/fa/caret-left';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import { fullWhite, orange500, grey400 } from 'material-ui/styles/colors';
import CloseIcon from "mui-icons/cmdi/close-circle-outline";
import IconButton from 'material-ui/IconButton';
import Dialog from 'material-ui/Dialog';

const PDFUrlParams = { "url": process.env.REACT_APP_TERM_OF_USE_URL, "httpHeaders": {"Access-Control-Allow-Origin":"*"}}

const style = {
    buttons: {
        margin: '5px'
    },
    pageText: {
        color: grey400
    }
}

const renderLoadDialog = () => (
    <Dialog
        open={true}
        modal={false}
    >
        Carregando termo de uso...
    </Dialog>
)

class TermOfUse extends Component {

    onDocumentLoad = ({ numPages }) => {
        const { setNumberOfPages, setPageNumber } = this.props;
        setNumberOfPages(numPages);
        setPageNumber(1);
    }

    handlePreviousPage = () => {
        const { setPageNumber, termOfUse } = this.props;
        if (termOfUse.pageNumber > 1) {
            setPageNumber(termOfUse.pageNumber - 1);
        }
    }

    handleNextPage = () => {
        const { setPageNumber, termOfUse } = this.props;
        if (termOfUse.pageNumber < termOfUse.numberOfPages) {
            setPageNumber(termOfUse.pageNumber + 1);
        }
    }

    handleClose = () => {
        const { openCloseTermOfUse } = this.props;
        openCloseTermOfUse(false);
    }

    render() {
        const { termOfUse } = this.props;
        return (
            <Grid style={{marginBottom: '20px'}}>
                <Row center="md">
                    <Col md={6}>
                        <Row end="md">
                            <IconButton tooltip="Fechar Termo de uso" onClick={this.handleClose}>
                                <CloseIcon color={orange500} />
                            </IconButton>
                        </Row>
                        <Row start="md">
                            <Col md={12}>
                                <Document
                                file={PDFUrlParams}
                                onLoadSuccess={this.onDocumentLoad}
                                loading={renderLoadDialog()}
                                >
                                    <Page pageNumber={termOfUse.pageNumber} />
                                </Document>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row center="md">
                    <Col>
                        <p style={style.pageText}>Página {termOfUse.pageNumber} de {termOfUse.numberOfPages}</p>
                    </Col>
                </Row>
                {termOfUse.numberOfPages > 0 &&
                    <Row center="md">
                        <Col md={1}>
                            <RaisedButton
                                onClick={this.handlePreviousPage}
                                style={style.buttons}
                                backgroundColor={orange500}
                                icon={<FaCaretLeft color={fullWhite} />}
                                disabled={termOfUse.pageNumber <= 1}
                                />
                        </Col>
                        <Col md={2}>
                            <FlatButton
                                label="Fechar"
                                
                                onClick={this.handleClose}
                                
                                icon={<CloseIcon />}
                                /> 
                        </Col>
                        <Col md={1}>      
                            <RaisedButton
                                onClick={this.handleNextPage}
                                style={style.buttons}
                                backgroundColor={orange500}
                                disabled={termOfUse.pageNumber >= termOfUse.numberOfPages}
                                icon={<FaCaretRight color={fullWhite} />}
                                />
                        </Col>
                    </Row>
                }
            </Grid>
        );
    }
}

export default TermOfUse;