import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { SubmissionError } from 'redux-form';
import FooterMenu from '../FooterMenu';
import DialogUtil from '../DialogUtil';
import * as ApiHelper from '../../helpers/ApiHelper';
import { DomainField, EmailField, PasswordField, TermOfUseField, NameField } from '../../helpers/formInputHelper';

import Paper from 'material-ui/Paper';
import TipBox from '../TipBox';
import HomeIcon from 'mui-icons/cmdi/home';
import EmoticonExcitedIcon from 'mui-icons/cmdi/emoticon-excited';
import AccountLocationIcon from 'mui-icons/cmdi/account-location';
import SecurityIcon from 'mui-icons/cmdi/security';


import { logoColor } from '../../helpers/colorsHelper';

const paperStyle = {
  height: 'auto',
  marginLeft: 15,
  display: 'inline-block',
  padding: '0.8em',
};

class CheckEmailDomainStep extends Component {
  onSubmit = async (values) => {
    await this.checkInsertedDomain(values.domain);
    await this.checkInsertedEmail(values.email);
  };


  checkInsertedEmail = async (email) => {
    return ApiHelper.checkEmail(email)
        .then(emailAvailable => {
          if (!emailAvailable) {
            throw new SubmissionError({ emailInUse: true, _error: true });
          }
          const { currentStep, setStep } = this.props;

          if (currentStep < 4) {
            setStep(currentStep + 1);
          }
        })
        .catch(err => {
          if (err.errors.emailInUse === true) {
            throw new SubmissionError({ email: "Verificamos que este e-mail já está associado a uma conta na Mambo. Caso queira criar um novo painel, entre em contato conosco pelo e-mail: comercial@mambowifi.com", _error: true });
          }
          this.props.addError("Falha ao criar a conta na Mambo. Retentando em 30 segundos")
          this.retryChecks(email);
        });
  }

  checkInsertedDomain = async (domain) => {
    return ApiHelper.checkDomain(domain)
      .then(isAvailable => {
        if (!isAvailable) {
          throw new SubmissionError({ domainInUse: true, _error: true });
        }
      })
      .catch(err => {
        if (err.errors.domainInUse === true) {
          throw new SubmissionError({ domain: 'Domínio está em uso', _error: true });
        }
        this.props.addError("Falha ao criar a conta na Mambo. Retentando em 30 segundos")
        this.retryChecks(domain);
      });
  }


  retryChecks = async (domain) => {
    setTimeout(() => {
        this.checkInsertedDomain(domain);
    }, 30000);
  }

  getDomainTip = () => {
    return (
      <TipBox
        icon={<HomeIcon color={logoColor} style={{ "verticalAlign": "middle" }} />}
        label='Domínio'
        content={"Este será o domínio do seu Mambo WiFi. Ele não poderá ser alterado posteriormente. Escolha com cuidado!"} />
    );
  }

  getFullNameTip = () => {
    return (
      <TipBox
        icon={<AccountLocationIcon color={logoColor} style={{ "verticalAlign": "middle" }} />}
        label='Nome Completo'
        content={"Seu nome será utilizado como nome de usuário no painel de gerenciamento do seu Mambo WiFi."} />
    );
  }

  getEmailTip = () => {
    return (
      <TipBox
        icon={<AccountLocationIcon color={logoColor} style={{ "verticalAlign": "middle" }} />}
        label='E-mail'
        content={"Seu e-mail será utilizado como sua conta de usuário, através dele você conseguirá acessar seu painel de gerenciamento do Mambo WiFi."} />
    );
  }

  getPasswordTip = () => {
    return (
      <TipBox
        icon={<SecurityIcon color={logoColor} style={{ "verticalAlign": "middle" }} />}
        label='Senha'
        content={"Esta será sua senha para acessar seu painel do Mambo WiFi. As duas senhas devem ser iguais e possuir no mínimo 6 caracteres."} />
    );
  }

  getDefaultTip = () => {
    return (
      <TipBox
        icon={<EmoticonExcitedIcon color={logoColor} style={{ "verticalAlign": "middle" }} />}
        label='Bem vindo'
        content={"Este é o formulário de compra do Mambo WiFi! Primeiro você deverá informar o domínio e o e-mail para configurarmos sua conta."} />
    );
  }

  renderToolTip = (fieldName) => {
    const tips = {
      "domain": this.getDomainTip(),
      "name": this.getFullNameTip(),
      "email": this.getEmailTip(),
      "password": this.getPasswordTip(),
      "confirmPassword": this.getPasswordTip()
    }

    const tip = tips[fieldName];

    if (tip) {
      return (
        <Paper style={paperStyle} zDepth={1}>
          {tip}
        </Paper>
      );
    } else {
      return (
        <Paper style={paperStyle} zDepth={1}>
          {this.getDefaultTip()}
        </Paper>
      );
    }
  }

  render() {
    const {
      token,
      handleSubmit,
      currentStep,
      pristine,
      valid,
      submitting,
      openCloseTermOfUse,
      activeField
    } = this.props;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Grid style={{ marginBottom: '2em' }}>
          <Row center='md'>
            <Col md={6}>
              <Row start="md">
                <Col md={12}>
                  <DomainField />
                  <NameField required />
                  <EmailField
                    disabled={false}
                  />
                  {token === null && (
                    <PasswordField required />
                  )}
                  <TermOfUseField openCloseTermOfUse={openCloseTermOfUse} />
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <Row start="md">
                <Col md={12}>
                  {this.renderToolTip(activeField)}
                </Col>
              </Row>
            </Col>

          </Row>
        </Grid>

        <FooterMenu
          step={currentStep}
          handlePrev={() => { }}
          isDisabledNext={pristine || !valid || submitting}
          style={{ marginTop: "5em" }}
        />

        <DialogUtil
          modal={false}
          open={submitting}
          text='Validando dados informados'
        />

      </form>
    );
  }
}

export default CheckEmailDomainStep;
