import { change } from 'redux-form';
import { getAddress } from '../helpers/ApiHelper';
import { 
    addCep,
    addAddress,
    addBillingCep,
    addBillingAddress,
} from '../actions/clientAction';
import { 
    addIsLoadingAddress,
    removeIsLoadingAddress,
    addIsLoadingBillingAddress,
    removeIsLoadingBillingAddress,
} from '../actions/loadingActions';

export const loadAddress = (cep) => (dispatch) => {
    dispatch(addIsLoadingAddress());
    return getAddress(cep)
        .then(address => {
            dispatch(addAddress(address));
            dispatch(addCep(cep));
            dispatch(change('wizard', 'address', address.street));
            dispatch(change('wizard', 'district', address.district));
            dispatch(change('wizard', 'city', address.city));
            dispatch(change('wizard', 'state', address.state));
            dispatch(removeIsLoadingAddress());
        });
}

export const loadBillingAddress = (cep) => (dispatch) => {
    dispatch(addIsLoadingBillingAddress());
    return getAddress(cep)
        .then(address => {
            dispatch(addBillingAddress(address));
            dispatch(addBillingCep(cep));
            dispatch(change('wizard', 'billingAddress', address.street));
            dispatch(change('wizard', 'billingDistrict', address.district));
            dispatch(change('wizard', 'billingCity', address.city));
            dispatch(change('wizard', 'billingState', address.state));
            dispatch(removeIsLoadingBillingAddress());
        });
}

export const copyAddressToBillingAddress = ({ address, addressNumber, cep, district, city, state, addressComplement }) => (dispatch) => {
    dispatch(change('wizard', 'billingCep', cep));
    dispatch(change('wizard', 'billingAddress', address));
    dispatch(change('wizard', 'billingAddressNumber', addressNumber));
    dispatch(change('wizard', 'billingDistrict', district));
    dispatch(change('wizard', 'billingCity', city));
    dispatch(change('wizard', 'billingState', state));
    dispatch(change('wizard', 'billingAddressComplement', addressComplement));
}
