
export const parsePrice = (priceString) => {
    return new Intl.NumberFormat('pt-BR', { 
        style: 'currency', 
        currency: 'BRL',
        }).format(priceString);
};

export const calcDiscountInPercent = (basePrice, comparablePrice) => {
    const baseVal = Number.parseFloat(basePrice);
    const comparableVal = Number.parseFloat(comparablePrice);
    // Get difference
    const diff = baseVal - comparableVal;
  
    // Calcular percentage like, get integer value
    const result =  Math.round((diff/baseVal) * 100)
    
    // If result is zero, the prices is the same
    if (result  === 0) return { label: '0%', result};
    
    // if result is positive mean comparablePrice has discount so use "-" operator
    // like "-10%" mean 10% of discount comparable to base price.
    if (result > 0) return { label: `-${Math.abs(result)}%`, result};
    
    // If resul is negative men comparablePrice is over priced than basePrice
    // so use "+" operator like "+10%" mean increment 10% in the price
    if (result < 0) return { label: `+${Math.abs(result)}%`, result};
    
    return Error('Unable to calculate percentage')
};
