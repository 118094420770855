import React from 'react';
import { Field } from 'redux-form';
import InputField from '../components/InputField';
import TextField from 'material-ui/TextField';
import { logoColor } from '../helpers/colorsHelper';
import { grey500, grey700 } from 'material-ui/styles/colors';
import { Row, Col } from 'react-flexbox-grid';
import Checkbox from 'material-ui/Checkbox';
import {
  FIELD_MAX_LENGHT_CEP,
  FIELD_MAX_LENGHT_CNPJ,
  FIELD_MAX_LENGHT_CPF,
  FIELD_MAX_LENGHT_PHONE,
  FIELD_MAX_LENGTH_IE,
  FIELD_MAX_LENGTH_IM,
  FIELD_MAX_LENGTH_PASSWORD,
  FIELD_MAX_LENGTH_DOMAIN,
  onlyNumberNormalizer,
  maxLengthNormalizer,
} from '../helpers/formInputNormalizers';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import {
  getMonthValidity,
  getYearValidity,
  MAX_YEARS,
} from './creditCardHelper';
import { getJobAreas, getJobTypes } from './ClientHelper';

const renderCheckbox = (props) => {
  const {
    input,
    label,
    name,
    meta: { touched, error },
    ...custom
  } = props;
  return (
    <div>
      <Checkbox
        label={label}
        name={name}
        style={{ paddingTop: '2.3em' }}
        checked={input.value ? true : false}
        onCheck={input.onChange}
        onBlur={() => input.onBlur(input.value)}
        {...custom}
      />
      <div>
        {touched && (
          <span style={{ color: 'red', fontSize: 'small' }}>{error}</span>
        )}
      </div>
    </div>
  );
};

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  return (
    <SelectField
      floatingLabelStyle={{ color: grey500 }}
      errorText={touched && error}
      {...input}
      {...custom}
      onChange={(event, index, value) => input.onChange(value)}
      children={children}
    />
  );
};

export const DomainField = () => (
  <Row>
    <Col md={6}>
      <Field
        name="domain"
        component={InputField}
        floatingLabelText="Insira o domínio da conta"
        hintText="Domínio"
        style={{ marginTop: '1em' }}
        fullWidth={true}
        normalize={maxLengthNormalizer(FIELD_MAX_LENGTH_DOMAIN)}
      />
    </Col>
    <Col md={6}>
      <TextField
        floatingLabelText=".mambowifi.com"
        hintText=".mambowifi.com"
        style={{ marginTop: '1em' }}
        fullWidth={true}
        disabled={true}
        underlineDisabledStyle={{ border: 'none' }}
        floatingLabelStyle={{ color: logoColor }}
      />
    </Col>
  </Row>
);

export const NameField = ({ disabled, required }) => (
  <Field
    name="name"
    component={InputField}
    disabled={disabled ? true : false}
    floatingLabelText={required ? 'Nome*' : 'Nome'}
    hintText="Nome Completo"
    fullWidth={true}
    maxLength="80"
  />
);

export const EmailField = ({ disabled, required }) => {
  return (
    <Field
      name="email"
      component={InputField}
      floatingLabelText={required ? 'E-mail*' : 'E-mail'}
      hintText="meu_email@dominio.com"
      disabled={disabled ? true : false}
      inputStyle={{ color: grey700 }}
      fullWidth={true}
    />
  );
};

export const FinancialEmailField = ({ required }) => (
  <Field
    name="financialEmail"
    component={InputField}
    floatingLabelText={required ? 'E-mail financeiro*' : 'E-mail financeiro'}
    hintText="meu_email@dominio.com"
    disabled={false}
    fullWidth={true}
  />
);

export const PhoneField = ({ required }) => (
  <Field
    name="phone"
    component={InputField}
    floatingLabelText={
      required
        ? 'DDD + Telefone* (apenas números)'
        : 'DDD + Telefone (apenas números)'
    }
    hintText="11912345678"
    fullWidth={true}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGHT_PHONE)}
  />
);

export const CompanyField = ({ label, hintText }) => (
  <Field
    name="company"
    component={InputField}
    floatingLabelText={label}
    hintText={hintText}
    fullWidth={true}
  />
);

export const CPFField = ({ onChange, required }) => (
  <Field
    name="cpf"
    component={InputField}
    floatingLabelText={
      required ? 'CPF* (apenas números)' : 'CPF (apenas números)'
    }
    hintText="12345678910"
    fullWidth={true}
    onChange={onChange}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGHT_CPF)}
    maxLength="11"
  />
);

export const CNPJField = ({ onChange, required }) => (
  <Field
    name="cnpj"
    component={InputField}
    floatingLabelText="CNPJ (apenas números)"
    hintText="61187936000168"
    fullWidth={true}
    onChange={onChange}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGHT_CNPJ)}
    maxLength="14"
  />
);

export const InscricaoMunicipalField = ({ required }) => (
  <Field
    name="inscricaoMunicipal"
    component={InputField}
    floatingLabelText={
      required
        ? 'Inscrição municipal* (apenas números)'
        : 'Inscrição municipal (apenas números)'
    }
    hintText=""
    fullWidth={true}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGTH_IM)}
  />
);

export const InscricaoEstadualField = ({ isIEIsento, required }) => {
  let label = required
    ? 'Inscrição estadual* (apenas números)'
    : 'Inscrição estadual (apenas números)';
  label = isIEIsento ? `${label} (ISENTO)` : label;

  return (
    <Field
      name="inscricaoEstadual"
      component={InputField}
      floatingLabelText={label}
      hintText=""
      fullWidth={true}
      disabled={isIEIsento}
      normalize={onlyNumberNormalizer(FIELD_MAX_LENGTH_IE)}
    />
  );
};

export const CEPField = ({ onChange, required }) => (
  <Field
    name="cep"
    component={InputField}
    floatingLabelText={
      required ? 'CEP* (apenas números)' : 'CEP (apenas números)'
    }
    hintText="13330000"
    fullWidth={true}
    onChange={onChange}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGHT_CEP)}
  />
);

export const BillingCEPField = ({ onChange, required, disabled }) => (
  <Field
    name="billingCep"
    component={InputField}
    disabled={disabled}
    floatingLabelText={
      required ? 'CEP* (apenas números)' : 'CEP (apenas números)'
    }
    hintText="13330000"
    fullWidth={true}
    onChange={onChange}
    normalize={onlyNumberNormalizer(FIELD_MAX_LENGHT_CEP)}
  />
);

export const AddressField = ({ required }) => (
  <Field
    name="address"
    component={InputField}
    floatingLabelText={required ? 'Endereço*' : 'Endereço'}
    hintText="Av. Brasil"
    fullWidth={true}
  />
);

export const BillingAddressField = ({ required, disabled }) => (
  <Field
    name="billingAddress"
    disabled={disabled}
    component={InputField}
    floatingLabelText={required ? 'Endereço*' : 'Endereço'}
    hintText="Av. Brasil"
    fullWidth={true}
  />
);

export const AddressNumberField = ({ required }) => (
  <Field
    name="addressNumber"
    component={InputField}
    floatingLabelText={required ? 'Número*' : 'Número'}
    hintText="127"
    fullWidth={true}
  />
);

export const BillingAddressNumberField = ({ required, disabled }) => (
  <Field
    name="billingAddressNumber"
    component={InputField}
    disabled={disabled}
    floatingLabelText={required ? 'Número*' : 'Número'}
    hintText="127"
    fullWidth={true}
  />
);

export const DistrictField = () => (
  <Field
    name="district"
    component={InputField}
    floatingLabelText="Bairro*"
    hintText="Centro"
    fullWidth={true}
  />
);

export const BillingDistrictField = ({ disabled }) => (
  <Field
    name="billingDistrict"
    component={InputField}
    disabled={disabled}
    floatingLabelText="Bairro*"
    hintText="Centro"
    fullWidth={true}
  />
);

export const AddressComplementField = ({ required }) => (
  <Field
    name="addressComplement"
    component={InputField}
    floatingLabelText={required ? 'Complemento*' : 'Complemento'}
    hintText="Sala 99B"
    fullWidth={true}
  />
);

export const BillingAddressComplementField = ({ required, disabled }) => (
  <Field
    name="billingAddressComplement"
    disabled={disabled}
    component={InputField}
    floatingLabelText={required ? 'Complemento*' : 'Complemento'}
    hintText="Sala 99B"
    fullWidth={true}
  />
);

export const StateField = ({ required }) => (
  <Field
    name="state"
    component={InputField}
    floatingLabelText={required ? 'Estado*' : 'Estado'}
    hintText="SP"
    fullWidth={true}
  />
);

export const BillingStateField = ({ required, disabled }) => (
  <Field
    name="billingState"
    component={InputField}
    disabled={disabled}
    floatingLabelText={required ? 'Estado*' : 'Estado'}
    hintText="SP"
    fullWidth={true}
  />
);

export const CityField = ({ required }) => (
  <Field
    name="city"
    component={InputField}
    floatingLabelText={required ? 'Cidade*' : 'Cidade'}
    hintText="São Paulo"
    fullWidth={true}
  />
);

export const BillingCityField = ({ required, disabled }) => (
  <Field
    name="billingCity"
    component={InputField}
    disabled={disabled}
    floatingLabelText={required ? 'Cidade*' : 'Cidade'}
    hintText="São Paulo"
    fullWidth={true}
  />
);

export const PasswordField = ({ required }) => (
  <Row>
    <Col md={6}>
      <Field
        type="password"
        name="password"
        component={InputField}
        floatingLabelText={required ? 'Senha de acesso*' : 'Senha de acesso'}
        hintText="Senha"
        fullWidth={true}
        normalize={maxLengthNormalizer(FIELD_MAX_LENGTH_PASSWORD)}
      />
    </Col>
    <Col md={6}>
      <Field
        type="password"
        name="confirmPassword"
        component={InputField}
        floatingLabelText="Confirmar Senha*"
        hintText="Confirmar Senha"
        fullWidth={true}
        normalize={maxLengthNormalizer(FIELD_MAX_LENGTH_PASSWORD)}
      />
    </Col>
  </Row>
);

export const IsentoIECheckbox = () => (
  <Field name="isIEIsento" label="Isento I.E." component={renderCheckbox} />
);

export const CopyEmailCheckbox = ({ onChange }) => (
  <Field
    name="isCopyEmail"
    label="Copiar e-mail"
    onChange={onChange}
    component={renderCheckbox}
  />
);

export const CopyBillingAddressCheckbox = ({ onChange }) => (
  <Field
    name="isCopyBillingAddress"
    label="Copiar endereço do cadastro"
    onChange={onChange}
    component={renderCheckbox}
  />
);

export const CreditCardNumberField = ({ required }) => (
  <Field
    name="creditCardNumber"
    component={InputField}
    floatingLabelText={required ? 'Número do cartão*' : 'Número do cartão'}
    hintText="1111222233334444"
    fullWidth={true}
    normalize={onlyNumberNormalizer(16)}
  />
);

export const CvvCreditCardField = ({ required }) => (
  <Field
    name="creditCardCvv"
    component={InputField}
    floatingLabelText={
      required ? 'Código de segurança*' : 'Código de segurança'
    }
    hintText="333"
    fullWidth={true}
    normalize={onlyNumberNormalizer(4)}
  />
);

export const NameCreditCardField = ({ required }) => (
  <Field
    name="creditCardName"
    component={InputField}
    floatingLabelText={required ? 'Nome titular*' : 'Nome titular'}
    hintText="Tony Stark"
    fullWidth={true}
  />
);

export const MonthExpireCreditCardField = () => (
  <Field
    name="monthValidityCreditCard"
    floatingLabelText="Mês*"
    component={renderSelectField}
    fullWidth={true}
  >
    {getMonthValidity().map((month) => (
      <MenuItem key={month} value={month} primaryText={month} />
    ))}
  </Field>
);

export const YearExpireCreditCardField = () => (
  <Field
    name="yearValidityCreditCard"
    floatingLabelText="Ano*"
    fullWidth={true}
    component={renderSelectField}
  >
    {getYearValidity(MAX_YEARS).map((year) => (
      <MenuItem key={year} value={year} primaryText={year} />
    ))}
  </Field>
);

export const JobAreasField = () => (
  <Field
    name="jobArea"
    floatingLabelText="Área de atuação da empresa*"
    fullWidth={true}
    component={renderSelectField}
  >
    {getJobAreas().map((item) => (
      <MenuItem key={item} value={item} primaryText={item} />
    ))}
  </Field>
);

export const JobTypesField = () => (
  <Field
    name="jobType"
    floatingLabelText="Cargo*"
    fullWidth={true}
    component={renderSelectField}
  >
    {getJobTypes().map((item) => (
      <MenuItem key={item} value={item} primaryText={item} />
    ))}
  </Field>
);

export const VoucherField = ({ hintText = '' }) => (
  <Field
    name="voucher"
    component={InputField}
    floatingLabelText="Digite aqui o código do seu cupom"
    hintText={hintText}
    fullWidth={true}
  />
);

export const TermOfUseField = ({ openCloseTermOfUse }) => {
  const label = (
    <span>
      Li e concordo com os termos de uso da Mambo WiFi.&nbsp;&nbsp;
      <a
        href="https://uploads.wspot.com.br/contratacao/eula-2022.pdf"
        style={{ position: 'relative', zIndex: 3 }}
        onClick={(event) => {
          event.preventDefault();
          openCloseTermOfUse(true);
        }}
      >
        Termos de uso
      </a>
    </span>
  );
  return <Field name="termOfUse" label={label} component={renderCheckbox} />;
};
