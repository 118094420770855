import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ConfirmationStep from '../../components/steps/ConfirmationStep';
import { fetchChangeStepIndex } from '../../actions/stepActions';
import { addError } from '../../actions/errorActions';
import { getClientData } from '../../helpers/ClientHelper';
import { addRedirectUrl } from '../../actions/clientAction';

const mapStateToProps = (state) => {
    const token = state.client.token;
    const { currentStep } = state;
    const { clientData, planInfo } = getClientData(state);

    return {
        token,
        currentStep,
        clientData,
        planInfo
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStep: (stepIndex) => dispatch(fetchChangeStepIndex(stepIndex)),
        addError: (message) => dispatch(addError(message)),
        addRedirectUrl: (redirectUrl) => dispatch(addRedirectUrl(redirectUrl))
    }
};

const ConnectedComponent = reduxForm({
    form: "wizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(ConfirmationStep);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
