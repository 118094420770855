import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

class ErrorToastBar extends Component {
    
    openErrorToast(message) {
        toast.error(message, {
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true,
        });
    }

    render() {
        const { message, isOpen } = this.props;
        return (
            <div>
                { isOpen && this.openErrorToast(message) }
                <ToastContainer />
            </div>
        )
    }
};

export default ErrorToastBar;
