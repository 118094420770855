import React from 'react';
import {Grid, Row, Col} from 'react-flexbox-grid';
import DialogUtil from './DialogUtil';
import {
    EmailField,
    CPFField,
    NameField,
    PhoneField,
    FinancialEmailField,
    CopyEmailCheckbox,
    CEPField,
    AddressField,
    AddressNumberField,
    DistrictField,
    CityField,
    StateField,
    AddressComplementField,
} from '../helpers/formInputHelper';

const ClientFisicoForm = ({ onChangeCep, isLoadingAddress, onCopyEmail, onDocumentChange }) => {
    return <Grid>
        <Row center='md'>
            <Col md={8}>
                <Row start='md'>
                    <Col md={12}>
                        <NameField disabled required />
                    </Col>

                    <Col md={7}>
                        <EmailField disabled required />
                    </Col>
                    <Col md={5}>
                        <PhoneField required />
                    </Col>

                    <Col md={9}>
                        <FinancialEmailField required />
                    </Col>
                    <Col md={3}>
                        <CopyEmailCheckbox onChange={onCopyEmail} />
                    </Col>

                    <Col md={12}>
                        <CPFField onChange={ onDocumentChange } required />
                    </Col>

                    <Col md={12}>
                        <CEPField onChange={ onChangeCep } required/>
                    </Col>

                    <Col md={8}>
                        <AddressField required />
                    </Col>
                    <Col md={4}>
                        <AddressNumberField required />
                    </Col>

                    <Col md={4}>
                        <DistrictField required />
                    </Col>
                    <Col md={4}>
                        <CityField required />
                    </Col>
                    <Col md={4}>
                        <StateField required />
                    </Col>

                    <Col md={12}>
                        <AddressComplementField />
                    </Col>
                </Row>
            </Col>
        </Row>

        <DialogUtil
            modal={false}
            open={isLoadingAddress}
            text='Carregando informações de endereço, aguarde.'
            />
    </Grid>
}

export default ClientFisicoForm;