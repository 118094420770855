import request from 'request-promise';

const apiCheckDomainEndpoint = process.env.REACT_APP_API_CHECK_DOMAIN;
const apiCheckCreditCardEndpoint =
  process.env.REACT_APP_ERP_VALIDATE_CREDIT_CARD;
const apiCheckEmailEndpoint = process.env.REACT_APP_API_CHECK_EMAIL;
const apiCheckDocumentEndpoint = process.env.REACT_APP_API_CHECK_DOCUMENT;
const producerPort = process.env.REACT_APP_PURCHASE_API_URL;
const consumerPort = process.env.REACT_APP_ERP_SERVICE_URL;
const apiCheckVoucherEndpoint = process.env.REACT_APP_API_CHECK_VOUCHER;
const apiClientTrial = process.env.REACT_APP_API_CLIENT_TRIAL;
const apiClientPurchase = process.env.REACT_APP_API_CLIENT_PURCHASE;
const apiClientByToken = process.env.REACT_APP_API_CLIENT_BY_TOKEN;
const apiToken = process.env.REACT_APP_API_TOKEN;
const cepBaseUrl = process.env.REACT_APP_API_CEP_BASE_URL;
const cepTypeReturn = process.env.REACT_APP_API_CEP_TYPE_RETURN;
const apiLoggerCep = process.env.REACT_APP_API_LOGGER_CEP;
const checkCep = process.env.REACT_APP_API_CHECK_CEP;

export const checkDomain = (domain) => {
  const options = {
    uri: `${producerPort}${apiCheckDomainEndpoint}/${domain}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => {
      return result.statusCode === 200 ? true : false;
    })
    .catch((err) => {
      if (err.statusCode === 409) {
        return false;
      }
      throw err;
    });
};

export const checkValidationCard = (creditCard) => {
  const options = {
    uri: `${producerPort}${apiCheckCreditCardEndpoint}`,
    method: 'POST',
    headers: {
      Authorization: apiToken,
    },
    body: creditCard,
    json: true,
  };

  return request(options)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw err;
    });
};

export const checkEmail = (email) => {
  const options = {
    uri: `${consumerPort}${apiCheckEmailEndpoint}/${email}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => {
      return result.statusCode === 200 ? true : false;
    })
    .catch((err) => {
      throw err;
    });
};

export const checkDocument = (document) => {
  const options = {
    uri: `${producerPort}${apiCheckDocumentEndpoint}/${document}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => {
      return result.statusCode === 200 ? true : false;
    })
    .catch((err) => {
      return false;
    });
};

export const checkVoucher = (voucher) => {
  const options = {
    uri: `${consumerPort}${apiCheckVoucherEndpoint}/${voucher}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => result)
    .catch((err) => {
      throw err;
    });
};

export const trial = ({
  domain,
  company,
  name,
  phone,
  jobArea,
  jobType,
  email,
  password,
}) => {
  const options = {
    method: 'POST',
    uri: `${producerPort}${apiClientTrial}`,
    headers: {
      Authorization: apiToken,
    },
    body: {
      domain: domain,
      admin: {
        fullName: name,
        email: email,
        phone: phone,
        jobArea: jobArea,
        jobType: jobType,
        password: password,
      },
      company: {
        name: company,
      },
    },
    json: true,
  };

  return request(options)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
};

export const purchase = (client) => {
  const options = {
    method: 'POST',
    uri: `${producerPort}${apiClientPurchase}`,
    headers: {
      Authorization: apiToken,
    },
    body: client,
    json: true,
  };

  return request(options)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAllPlans = () => {
  const options = {
    uri: `${consumerPort}/v2/plans`,
    method: 'GET',
    headers: {
      Authorization: 'Basic YXBpX2NvbnRyYXRhY2FvOkFwMUMwbnQ0dGFjNDAk',
    },
    json: true,
  };
  return request(options)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getClientDataByToken = (token) => {
  const options = {
    uri: `${producerPort}${apiClientByToken}/${token}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => result)
    .catch((err) => {
      throw err;
    });
};

export const getAddress = (cep) => {
  const options = {
    uri: `${cepBaseUrl}${cep}${cepTypeReturn}`,
    method: 'GET',
    json: true,
  };

  return request(options).then((result) => {
    if (result.erro) {
      return {
        street: '',
        district: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
      };
    }

    return {
      street: result.logradouro,
      district: result.bairro,
      city: result.localidade,
      state: result.uf,
      country: 'Brasil',
      zipCode: cep,
    };
  });
};

export const logError = ({ level, message, extra }) => {
  const options = {
    method: 'POST',
    uri: `${apiLoggerCep}`,
    headers: {
      Authorization: apiToken,
    },
    body: {
      level: level,
      message: message,
      extra: extra,
    },
    json: true,
  };

  return request(options)
    .then((response) => response)
    .catch((err) => {
      throw err;
    });
};

export const checksCep = (values) => {
  const options = {
    uri: `${consumerPort}${checkCep}${values.cep}`,
    method: 'GET',
    headers: {
      Authorization: apiToken,
    },
    json: true,
  };

  return request(options)
    .then((result) => result)
    .catch((err) => {
      throw err;
    });
};
