import React from 'react';
import SingleStep from './SingleStep';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
    CHECK_EMAIL_DOMAIN_STEP,
    SELECT_PLAN_STEP,
    FILL_FORM_STEP,
    CONFIRM_INFO_STEP,
    PAYMENT_INFO_STEP,
} from '../helpers/stepHelper';
import { Stepper } from 'material-ui/Stepper';
 
const HeaderSteper = (props) => {
    
    const {
        currentStep,
        handleStep
    } = props;
    
    return (
        <Grid>
            <Row center="md" style={{marginTop: '2em'}}>
                <Col md={10}>
                    <Stepper linear={false} activeStep={currentStep}>
                        <SingleStep 
                            step={CHECK_EMAIL_DOMAIN_STEP}
                            currentStep={currentStep}
                            handleStep={() => handleStep(CHECK_EMAIL_DOMAIN_STEP)}
                            />
                        <SingleStep 
                            step={SELECT_PLAN_STEP}
                            currentStep={currentStep}
                            handleStep={() => handleStep(SELECT_PLAN_STEP)}
                            />
                        <SingleStep 
                            step={FILL_FORM_STEP}
                            currentStep={currentStep}
                            handleStep={() => handleStep(FILL_FORM_STEP)}
                            />
                        <SingleStep 
                            step={PAYMENT_INFO_STEP}
                            currentStep={currentStep}
                            handleStep={() => handleStep(PAYMENT_INFO_STEP)}
                            />
                        <SingleStep 
                            step={CONFIRM_INFO_STEP}
                            currentStep={currentStep}
                            handleStep={() => handleStep(CONFIRM_INFO_STEP)}
                            />
                    </Stepper>
                </Col>
            </Row>       
        </Grid>
    );
}

export default HeaderSteper;
