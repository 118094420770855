import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { logoColor } from '../helpers/colorsHelper';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress';

const DialogUtil = (props) => {
  const { modal, open, text} = props;
  return (
    <Dialog modal={modal} open={open}>
        <Grid>
            <Row center="md">
                <Col md={12}>
                    {text}
                </Col>
            </Row>
            <Row center="md" style={{marginTop: '2em'}}>
                <Col md={12}>
                    <CircularProgress color={logoColor} size={60} />
                </Col>
            </Row>
        </Grid>            
    </Dialog>
  )
}

export default DialogUtil;