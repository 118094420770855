import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import { Grid, Row, Col } from 'react-flexbox-grid';
import imageAp from '../img/img-aparelhos.png'
import styled from 'styled-components';
import { logoColor } from '../helpers/colorsHelper';
import { grey500 } from 'material-ui/styles/colors';

const Title = styled.h2`
    color: ${logoColor};
    padding:0;
    margin-left: 0.8em;
`;

const Info = styled.p`
    color: ${grey500};
    margin-left: 1em;
    margin-bottom: 0.2em;
`;

const Highlight = styled.span`
    color: ${logoColor};
`;

const SalesContactCard = (props) => {
    const { numberOfAps } = props;
    return (
      <Paper style={{padding: '1em'}} zDepth={1}>
        <Grid>
            <Row start="md">
                <Col md={4}><img src={imageAp} alt="Ilustração de ponto de acesso" /></Col>
                <Col md={8}>
                    <Title>{numberOfAps} OU + EQUIPAMENTOS</Title>
                    <Info>
                        Entre em contato conosco.
                    </Info>
                    <Info>E-mail <Highlight>comercial@mambowifi.com</Highlight></Info>
                    <Info>Telefones <Highlight>(19) 3342-8026 / (19) 3342-0008</Highlight></Info>
                </Col>
            </Row>
        </Grid>
      </Paper>
    )
}

SalesContactCard.propTypes = {
    numberOfAps: PropTypes.number.isRequired,
}

export default SalesContactCard;
