import { connect } from 'react-redux';
import HomePage from '../../components/pages/HomePage';

const mapStateToProps = (state) => {
  const { loading } = state;
  return { 
      loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);