import { connect } from 'react-redux';
import SelectPlanStep from '../../components/steps/SelectPlanStep'
import { fetchChangeStepIndex } from '../../actions/stepActions';
import { setNumberOfAps } from '../../actions/numberOfApsAction';
import { selectPlanType } from '../../actions/planTypeAction';

const mapStateToProps = (state) => {
    const { currentStep, error, plans, loading, numberOfAps, planType, client } = state;
    return {
      currentStep,
      error,
      plans,
      loading,
      numberOfAps,
      planType,
      client,
    };
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
      setStep: (stepIndex) => dispatch(fetchChangeStepIndex(stepIndex)),
      setNumberOfAps: (total) => dispatch(setNumberOfAps(total)),
      selectPlanType: (planType) => dispatch(selectPlanType(planType)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SelectPlanStep);