import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import mamboDefault from '../img/logo-mambo.png'
import { Link } from 'react-router-dom';

const Header = ({ bgColor, logo = mamboDefault }) => (
  <Grid
    fluid
    style={{
      paddingTop: '1em',
      paddingBottom: '0.75em',
      backgroundColor: bgColor ? bgColor : '#ec213a',
    }}
  >
    <Row>
      <Col md={12} sm={12}>
        <Grid>
          <Row>
            <Col md={12}>
              <Link to="/">
                <img src={logo} alt="Logo Mambo Wifi" width="10%" />
              </Link>
            </Col>
          </Row>
        </Grid>
      </Col>
    </Row>
  </Grid>
);

export default Header;
