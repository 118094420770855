import { CHANGE_STEP_INDEX } from '../actions/stepActions';

export const currentStep = (state = 0, action) => {
    const { type } = action;
    switch(type) {
        case CHANGE_STEP_INDEX:
            return action.step;
        default:
            return state;
    }
}
