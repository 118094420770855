import { fetchChangeStepIndex } from './stepActions';

export const ADD_PAYMENT_TYPE = 'ADD_PAYMENT_TYPE';
export const ADD_CARD_BRAND = 'ADD_CARD_BRAND';
export const ADD_VOUCHER = 'ADD_VOUCHER';

export const addPaymentType = (paymentType) => ({
    type: ADD_PAYMENT_TYPE,
    paymentType,
});

export const addCardBrand = (cardBrand) => ({
    type: ADD_CARD_BRAND,
    cardBrand,
});

export const addVoucher = (voucher) => ({
    type: ADD_VOUCHER,
    voucher,
});

export const processCardBrandAndGoNextStep = (cardBrand, nextStep) => (dispatch) => {
    dispatch(addCardBrand(cardBrand));
    dispatch(fetchChangeStepIndex(nextStep));
};
