import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import TrialPage from '../../components/pages/TrialPage';
import { syncValidation } from '../../helpers/validateTrialHelper';
import { addError } from '../../actions/errorActions';
import { addRedirectUrl } from '../../actions/clientAction';
import { openCloseTermOfUse } from '../../actions/termOfUseAction'

const mapStateToProps = (state) => {
  const { loading, termOfUse } = state;
  return {
    loading,
    termOfUse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addError: (message) => dispatch(addError(message)),
    addRedirectUrl: (redirectUrl) => dispatch(addRedirectUrl(redirectUrl)),
    openCloseTermOfUse: (isOpen) => dispatch(openCloseTermOfUse(isOpen))
  }
};

const ConnectedComponent = reduxForm({
  form: 'trial',
  validate: syncValidation,
  destroyOnUnmount: false,
})(TrialPage);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
