import { connect } from 'react-redux';
import TermOfUse from '../components/TermOfUse';
import { 
    openCloseTermOfUse,
    setNumberOfPages,
    setPageNumber,
} from '../actions/termOfUseAction';

const mapStateToProps = (state) => {
    const { termOfUse } = state;
    return {
        termOfUse
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        openCloseTermOfUse: (isOpen) => dispatch(openCloseTermOfUse(isOpen)),
        setNumberOfPages: (number) => dispatch(setNumberOfPages(number)),
        setPageNumber: (number) => dispatch(setPageNumber(number))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUse);
