import { ADD_PAYMENT_TYPE, ADD_CARD_BRAND, ADD_VOUCHER } from '../actions/paymentAction';
import { PAYMENT_CREDIT_CARD } from '../helpers/paymentType';

const defaultState = {
    type: PAYMENT_CREDIT_CARD,
    cardBrand: null,
    voucher: null,
};

export const payment = (state = defaultState, action) => {
    switch(action.type) {
        case ADD_PAYMENT_TYPE:
            return {
                ...state,
                type: action.paymentType,
            }
        case ADD_CARD_BRAND:
            return {
                ...state,
                cardBrand: action.cardBrand,
            }
        case ADD_VOUCHER:
            return {
                ...state,
                voucher: action.voucher,
            }
        default:
            return state;
    }
}
