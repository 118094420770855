import React, { Component } from 'react';
import PaymentTypeSwitch from '../PaymentTypeSwitch';
import 'react-credit-cards/es/styles-compiled.css';
import FooterMenu from '../FooterMenu';
import { SubmissionError } from 'redux-form';
import CreditCardPaymentForm from '../CreditCardPaymentForm';
import BoletoPaymentForm from '../BoletoPaymentForm';
import VoucherForm from '../VoucherForm';
import * as ApiHelper from '../../helpers/ApiHelper';
import { PAYMENT_BOLETO, PAYMENT_CREDIT_CARD } from '../../helpers/paymentType';
import * as CreditCard from '../../helpers/validators/creditCard';

class PaymentInfoStep extends Component {
    onChangePaymentType = (event, value) => {
        this.props.addPaymentType(value);
    }

    onSubmit = async (values) => {
        const {currentStep, processCardBrandAndGoNextStep, creditCardNumber, paymentType } = this.props;
        const voucher = values.voucher;
        this.props.addVoucher(null);

        if (voucher) {
            await this.validateVoucher(voucher);
        }
        if(paymentType === "credit_card"){
            await this.validatecreditCard(values.domain);
        }
        
        let cardBrand = CreditCard.determineCardType(creditCardNumber);
        cardBrand = cardBrand ? cardBrand.toLowerCase() : cardBrand;
        processCardBrandAndGoNextStep(cardBrand, currentStep + 1);
    }


    validateVoucher = async (voucher) => {
        return  ApiHelper.checkVoucher(voucher)
        .then(result => {                  
            this.props.addVoucher(result);
        })
        .catch(response => {
            if (response.statusCode === 404) {
                throw new SubmissionError({voucher: 'Cupom inválido, expirado ou já utilizado.', _error: true});
            }
            throw new SubmissionError({voucher: 'Falha ao consultar o cupom.', _error: true});
        });
    }

    validatecreditCard = async (domain) => {
        const {creditCardNumber, monthValidityCreditCard, yearValidityCreditCard, creditCardCvv, creditCardName} = this.props;
        let cardBrand = CreditCard.determineCardType(creditCardNumber);
            let brandsAccepted = ['VISA','MASTER','AMEX','DINERS','ELO'] 
            let today = new Date()
            if(!brandsAccepted.includes(String(cardBrand))){
                window.scrollTo(0, 0);
                throw new SubmissionError({creditCardNumber: 'Bandeira do cartão não aceita.', _error: true});
            }else if(today.getMonth() > monthValidityCreditCard && today.getFullYear() === yearValidityCreditCard){
                window.scrollTo(0, 0);
                throw new SubmissionError({monthValidityCreditCard: 'Cartão vencido.', _error: true});
            }
            let CreditCardInfo = {
                "cardNumber": creditCardNumber,
                "holder": creditCardName,
                "expirationDate": monthValidityCreditCard+"/"+yearValidityCreditCard,
                "securityCode": creditCardCvv,
                "brand": cardBrand,
                "domain": domain,
            }
            return ApiHelper.checkValidationCard(CreditCardInfo)
                .then(result => {    
                    if(result.paymentStatus === "NOT_AUTHORIZED"){
                        window.scrollTo(0, 0);
                        throw new SubmissionError({creditCardNumber: 'Não conseguimos validar seu cartão. Erro: ' + result.message, _error: true});
                    }
                })
                .catch(err => {
                    throw new SubmissionError({creditCardNumber: 'Não conseguimos validar seu cartão.', _error: true});
                });
    }

    handlePrev = () => {
        const {currentStep, setStep} = this.props;
        setStep(currentStep - 1);
    }

    render() {
        const {
            paymentType,
            handleSubmit,
            pristine,
            submitting,
         } = this.props;

        return (
            <div>
                <PaymentTypeSwitch 
                    paymentType={paymentType}
                    onChange={this.onChangePaymentType}
                />
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    {paymentType === PAYMENT_CREDIT_CARD 
                        && <CreditCardPaymentForm {...this.props} />}
                    
                    {paymentType === PAYMENT_BOLETO
                        && <BoletoPaymentForm />}
                    
                    <VoucherForm />

                    <FooterMenu
                        handlePrev={this.handlePrev}
                        isDisabledNext={!paymentType || pristine || submitting}
                        style={{marginTop: "2em", marginBottom: "2em"}}  />
                </form>
            </div>
        )
    }
}

export default PaymentInfoStep;