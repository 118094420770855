import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Divider from 'material-ui/Divider';
import { 
    grey600,
    grey400,
} from 'material-ui/styles/colors';
import styled from 'styled-components';
import PointUpIcon from 'mui-icons/cmdi/cursor-pointer';
import { PESSOA_FISICA, PESSOA_JURICIA } from '../helpers/clientType';

const styles = {
    radioButton: {
        width: '12em',
        fontSize: '0.9em',
    },
    radioBox: { 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1em',
        marginBottom: '1em'
    }
};

const NoTypeWarning = styled.p`
    color: ${grey400};
    text-align: center;
`;

const renderWarningMessage = () => (
    <NoTypeWarning>
        <PointUpIcon color={grey400} /> selecione o tipo de cadastro.
    </NoTypeWarning>
);

const ClientTypeSwitch = (props) => {
    const { clientType, onChange } = props;
    return (
        <Grid>
            <Row center='md'>
                <Col md={4}>
                    <Divider />
                    <RadioButtonGroup 
                        onChange={onChange} 
                        name="shipSpeed" 
                        style={styles.radioBox}
                        valueSelected={clientType}>

                        <RadioButton
                            value={PESSOA_JURICIA}
                            label="Pessoa jurídica"
                            style={styles.radioButton}
                            labelStyle={{color:grey600}}
                        />
                        <RadioButton
                            value={PESSOA_FISICA}
                            label="Pessoa física"
                            style={styles.radioButton}
                            labelStyle={{color:grey600}}
                        />

                    </RadioButtonGroup>
                    <Divider />
                    {!clientType && renderWarningMessage()}
                </Col>
            </Row>
        </Grid>
    )
}

ClientTypeSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    clientType: PropTypes.string,
};

export default ClientTypeSwitch;
