import React, { Component } from 'react';
import Header from '../Header';


class RotaNova extends Component {
    render() {
        return (
            <div style={{ height: "100%" }}>
                <Header />
                <div>
                    <center>
                        <h2>Recebemos sua solicitação!</h2><br />
                        <h3>Estamos criando o seu Mambo WiFi com as informações inseridas.</h3>
                        <h3>Assim que finalizarmos, você recebera todos os dados de acesso em seu e-mail.</h3>
                    </center>
                </div>

            </div>
        )
    }
}

export default RotaNova;
