import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { CEP_LENGTH } from '../helpers/validateHelper';
import Cards from 'react-credit-cards';
import {
    CreditCardNumberField,
    CvvCreditCardField,
    NameCreditCardField,
    BillingAddressField,
    MonthExpireCreditCardField,
    YearExpireCreditCardField,
    BillingAddressNumberField,
    BillingCEPField,
    BillingCityField,
    BillingStateField,
    BillingDistrictField,
    BillingAddressComplementField,
    CopyBillingAddressCheckbox,
} from '../helpers/formInputHelper';
import { logoColor } from '../helpers/colorsHelper';
import styled from 'styled-components';
import DialogUtil from './DialogUtil';
import elo from '../img/elo.svg';
import visa from '../img/visa.svg';
import mastercard from '../img/mastercard.svg';
import diners from '../img/diners.svg';
import american from '../img/american.svg';

const AddressLabel = styled.p`
    color: ${logoColor};
    margin-bottom: 0;
    margin-top: 2em;
`;

class CreditCardPaymentForm extends Component {

    onChangeBillingCep = (event, newCep, previousCep, name) => {
        const { loadBillingAddress } = this.props;
        
        if (newCep && newCep.length === CEP_LENGTH) {
            loadBillingAddress(newCep);
        }
    };

    onChangeIsCopyAddress = (event, newValue, previousValue, name) => {
        const { copyAddressToBillingAddress, address } = this.props;
        if (newValue) {
            copyAddressToBillingAddress(address);
        }
    };

    renderCreditCardFields = () => {
        return (
            <Grid>
                <Row>
                    <Col md={6}>
                        <CreditCardNumberField required />
                    </Col>
                    <Col md={6}>
                        <NameCreditCardField required />
                    </Col>
                    <Col md={4}>
                        <MonthExpireCreditCardField />
                    </Col>
                    <Col md={4}>
                        <YearExpireCreditCardField />
                    </Col>
                    <Col md={4}>
                        <CvvCreditCardField required />
                    </Col>
                </Row>
            </Grid>
        );
    }

    renderBillingAddressFields = () => {
        const { isCopyBillingAddress } = this.props;
        return (
            <Grid>
                <Row>
                    <Col md={12}>
                        <AddressLabel>Endereço de faturamento</AddressLabel>
                    </Col>
                    <Col md={12}>
                        <CopyBillingAddressCheckbox onChange={this.onChangeIsCopyAddress} />
                    </Col>
                    <Col md={12}>
                        <BillingCEPField required disabled={isCopyBillingAddress} onChange={this.onChangeBillingCep} />
                    </Col>
                    <Col md={8}>
                        <BillingAddressField required disabled={isCopyBillingAddress} />
                    </Col>
                    <Col md={4}>
                        <BillingAddressNumberField required disabled={isCopyBillingAddress} />
                    </Col>
                    <Col md={4}>
                        <BillingDistrictField required disabled={isCopyBillingAddress} />
                    </Col>
                    <Col md={4}>
                        <BillingCityField required disabled={isCopyBillingAddress} />
                    </Col>
                    <Col md={4}>
                        <BillingStateField required disabled={isCopyBillingAddress} />
                    </Col>
                    <Col md={12}>
                        <BillingAddressComplementField disabled={isCopyBillingAddress} />
                    </Col>
                </Row>
            </Grid>
        )
    }

    render() {
        const {
            isLoadingBillingAddress,
            monthValidityCreditCard,
            yearValidityCreditCard,
            creditCardCvv,
            creditCardNumber, 
            creditCardName,
            cardFocusOn,
         } = this.props;
         
        return (
            <Grid style={{ marginTop: '1em' }}>
                    <Row center='md'>
                        <Col md={10}>
                            <Grid>
                                <Row start='md'>
                                    <Col md={8}>     
                                            {this.renderCreditCardFields()}
                                            {this.renderBillingAddressFields()}
                                    </Col> 
                                    <Col md={4}>
                                        <div style={{marginTop: '1em'}}>
                                            <Cards
                                                number={creditCardNumber}
                                                name={creditCardName}
                                                expiry={`${monthValidityCreditCard}/${yearValidityCreditCard}`}
                                                cvc={creditCardCvv}
                                                focused={cardFocusOn}
                                                />
                                        </div>
                                        <div>
                                            <AddressLabel>Cartões aceitos:</AddressLabel>
                                            <div>
                                            <img src={visa} title="Visa" alt="Visa" style={{height: '25px', width: '55px',marginRight: '10px',marginBottom: '8px'}}/><img src={mastercard} title="MasterCard" alt="MasterCard" style={{height: '25px', width: '40px',marginBottom: '8px'}}/><img src={diners} alt="Diners Club International" title="Diners Club International" style={{height: '40px', width: '45px'}}/><img src={american} title="American Express" alt="American Express" style={{height: '32px', width: '40px',marginBottom: '3px'}}/><img src={elo} title="Elo" alt="Elo" style={{height: '25px', width: '70px',marginBottom: '8px'}}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Grid>
                        </Col>
                    </Row>
                    
                    <DialogUtil
                        modal={false}
                        open={isLoadingBillingAddress}
                        text='Carregando informações de endereço, aguarde.'/>
                </Grid>
        )
    }
}

export default CreditCardPaymentForm;