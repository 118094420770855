import React from 'react';
import ErrorToastBar from './ErrorToastBar';

const ErrorAlert = (props) => {
    const { error } = props;
    return (
        <ErrorToastBar 
            isOpen={error 
                ? true
                : false}
             message={error} />
    )
}

export default ErrorAlert;
