import { getAllPlans } from '../helpers/ApiHelper';
import { addIsLoadingPlans, removeIsLoadingPlans } from './loadingActions';
import { addError } from './errorActions';

export const ADD_ALL_PLANS = "ADD_ALL_PLANS";

const addAllPlans = (plans) => ({
    type: ADD_ALL_PLANS,
    plans
});

export const fetchAddAllPlans = () => dispatch => {
    dispatch(addIsLoadingPlans());
    getAllPlans()
        .then(plans => {
            dispatch(addAllPlans(plans));
            dispatch(removeIsLoadingPlans());
        })
        .catch(err => {
            dispatch(addError("Ocorreu um erro, atualize a página e tenta novamente."))
        });
}
