import React from 'react';
import PropTypes from 'prop-types';
import { getStepInfo } from '../helpers/stepHelper';
import {
    Step,
    StepLabel
  } from 'material-ui/Stepper';

const SingleStep = (props) => {
    const { step, currentStep } = props;
    const content = getStepInfo(step, currentStep);

    return (
        <Step>
            <StepLabel
                icon={content.icon}
                style={content.style}>
                {content.label}
            </StepLabel>
        </Step>
    )
};

SingleStep.propTypes = {
    step: PropTypes.number.isRequired,
    currentStep: PropTypes.number.isRequired,
    handleStep: PropTypes.func.isRequired,
}

export default SingleStep;
