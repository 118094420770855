import React, { Component } from 'react';
import DialogUtil from '../DialogUtil';

class RedirectPage extends Component {
  render() {
    const redirectUrl = this.props.client.redirectUrl ? this.props.client.redirectUrl : '/';

    setTimeout(() => {
      window.location=redirectUrl
    }, 4000);

    return (
      <div>
        <DialogUtil
          modal={false}
          open={true}
          text='Sua conta foi criada com sucesso!!! Estamos te redirecionando para o painel administrativo.'
          />
      </div>
    )
  }
}

export default RedirectPage;
