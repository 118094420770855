import { connect } from 'react-redux';
import { reduxForm, getFormMeta } from 'redux-form';
import CheckEmailDomainStep from '../../components/steps/CheckEmailDomainStep';
import { fetchChangeStepIndex } from '../../actions/stepActions';
import { addError, removeError } from '../../actions/errorActions';
import { syncValidation } from '../../helpers/validateHelper';
import { openCloseTermOfUse } from '../../actions/termOfUseAction';

const mapStateToProps = (state) => {
    const token = state.client.token
    const { currentStep } = state;

    const meta = getFormMeta('wizard')(state);

    const activeField = (meta && Object.keys(meta).find(field => meta[field].active)) || null;

    return {
      token,
      currentStep,
      activeField
    };
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
      setStep: (stepIndex) => dispatch(fetchChangeStepIndex(stepIndex)),
      addError: (message) => dispatch(addError(message)),
      removeError: () => dispatch(removeError()),
      openCloseTermOfUse: (isOpen) => dispatch(openCloseTermOfUse(isOpen)),
    }
}

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(CheckEmailDomainStep);

export default reduxForm({
    form: "wizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: syncValidation,
})(ConnectedComponent);
