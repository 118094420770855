import { connect } from 'react-redux';
import RedirectPage from '../../components/pages/RedirectPage';

const mapStateToProps = (state) => {
  const { loading, client } = state;
  return {
    loading,
    client,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);
