import { SubmissionError } from "redux-form";
import * as ApiHelper from "./ApiHelper";
import { formValueSelector } from "redux-form";
import { getPlan } from "./PlanHelper";
import { PAYMENT_BOLETO, PAYMENT_CREDIT_CARD } from "./PaymentHelper";

export const PESSOA_JURIDICA = "pessoa_juridica";
export const PESSOA_FISICA = "pessoa_fisica";

const LEVEL = {
  INFO: 300,
  ERROR: 400,
  CRITICAL: 500,
};

export const createTrial = client => {
  return ApiHelper.checkDomain(client.domain)
    .then(domainAvailable => {

      if (!domainAvailable) {
        throw new SubmissionError({
          domain: "Domínio está em uso",
          _error: true
        });
      }
      return ApiHelper.checkEmail(client.email)
        .then(emailAvailable => {
          if (!emailAvailable) {
            throw new SubmissionError({
              email: "Verificamos que já existe uma conta da Mambo com este e-mail. Caso queira criar um novo painel, entre em contato conosco pelo e-mail: comercial@mambowifi.com",
              _error: true
            });
          }

          return ApiHelper.trial(client)
            .then(result => {
              return result;
            })
            .catch(err => {
              throw err;
            });
        })
        .catch(err => {
          throw err;
        });
    })
    .catch(err => {

      if (err instanceof SubmissionError) {
        throw err;
      } else {
        ApiHelper.logError({
          level: LEVEL.CRITICAL,
          message: "Falha ao criar a conta de teste no Mambo WiFi (Trial)",
          extra: err
        });

        throw new Error("Falha ao criar a conta de teste no Mambo WiFi.");
      }
    });
};

export const createPurchase = (token, client) => {
  if (token) {
    client.changePlan = true;
    client.admin.password = client.company.document;
  }

  return ApiHelper.purchase(client)
    .then(result => {
      if (result.status !== 200) {
        throw new Error();
      }
      return result;
    })
    .catch(err => {
      if (err instanceof SubmissionError) {
        throw err;
      } else {
        if (err.error.error === 'CREDIT_CARD_ERROR') {
          throw new Error("Falha ao processar os dados de seu cartão. Por favor, verifique se os dados estão corretos e tente novamente.");
        } else {
          ApiHelper.logError({
            level: LEVEL.CRITICAL,
            message: "Falha ao criar a conta no Mambo WiFi",
            extra: err
          });
          if (err.error.error === "WSPOT_UNAVAILABLE") {
            window.location = "/error"
          }
          if (err.error.error !== "ERP_UNAVAILABLE") {
            throw new Error("Falha ao criar a conta no Mambo WiFi.");
          }
        }
      }
    });
};

export const getClientData = store => {
  const selector = formValueSelector("wizard");

  const {
    domain,
    password,
    name,
    email,
    phone,
    address,
    addressNumber,
    district,
    city,
    state,
    cep,
    addressComplement,
    company,
    cpf,
    cnpj,
    inscricaoMunicipal,
    inscricaoEstadual,
    financialEmail,
    creditCardNumber,
    monthValidityCreditCard,
    yearValidityCreditCard,
    creditCardCvv,
    creditCardName,
    billingAddress,
    billingAddressNumber,
    billingDistrict,
    billingCity,
    billingState,
    billingCep,
    billingAddressComplement
  } = selector(
    store,
    "domain",
    "password",
    "name",
    "email",
    "phone",
    "address",
    "addressNumber",
    "district",
    "city",
    "state",
    "cep",
    "addressComplement",
    "company",
    "cpf",
    "cnpj",
    "inscricaoMunicipal",
    "inscricaoEstadual",
    "financialEmail",
    "creditCardNumber",
    "monthValidityCreditCard",
    "yearValidityCreditCard",
    "creditCardCvv",
    "creditCardName",
    "billingAddress",
    "billingAddressNumber",
    "billingDistrict",
    "billingCity",
    "billingState",
    "billingCep",
    "billingAddressComplement"
  );

  const paymentType = store.payment.type;
  const paymentCardBrand = store.payment.cardBrand;

  const plan = getPlan(store.planType, store.numberOfAps, store.plans);
  const planId = plan ? plan.id : null;

  const object = {
    erpId: store.client.clientData ? store.client.clientData.erpId : null,
    planId: planId,
    domain: domain,
    accessPoints: store.numberOfAps,
    admin: {
      fullName: name,
      email: email,
      phone: phone,
      password: password
    },
    company: {
      name: name,
      documentType: "CPF",
      document: cpf,
      inscricaoMunicipal: inscricaoMunicipal ? inscricaoMunicipal : "",
      inscricaoEstadual: inscricaoEstadual ? inscricaoEstadual : "ISENTO",
      address: {
        street: address,
        number: addressNumber,
        district: district,
        city: city,
        state: state,
        country: "Brasil",
        zipCode: cep,
        complement: addressComplement
      }
    },
    payment: {
      financialEmail: financialEmail,
      method: {
        type: PAYMENT_BOLETO
      },
      address: {
        street: address,
        number: addressNumber,
        district: district,
        city: city,
        state: state,
        country: "Brasil",
        zipCode: cep,
        complement: addressComplement
      },
      voucher: store.payment.voucher,
    }
  };

  //Company Info
  if (store.client.type === PESSOA_JURIDICA) {
    object.company.name = company;
    object.company.documentType = "CNPJ";
    object.company.document = cnpj;
  }

  // CreditCard Info
  if (paymentType === PAYMENT_CREDIT_CARD) {
    object.payment.method.type = "creditCard";
    object.payment.method.creditCardBrand = paymentCardBrand;
    object.payment.method.creditCardNumber = creditCardNumber;
    object.payment.method.creditCardValidityMonth = monthValidityCreditCard;
    object.payment.method.creditCardValidityYear = yearValidityCreditCard;
    object.payment.method.creditSecurityCode = creditCardCvv;
    object.payment.method.creditCardName = creditCardName;
    object.payment.address.street = billingAddress;
    object.payment.address.number = billingAddressNumber;
    object.payment.address.district = billingDistrict;
    object.payment.address.city = billingCity;
    object.payment.address.state = billingState;
    object.payment.address.zipCode = billingCep;
    object.payment.address.complement = billingAddressComplement;
  }

  return {
    clientData: object,
    planInfo: plan
  };
};

export const getJobAreas = () => [
  'Agência de Marketing/Publicidade',
  'Bares, Lanchonetes e Restaurantes',
  'Tecnologia',
  'Educação',
  'Varejo',
  'Comércio',
  'Instituição Financeira',
  'Consultoria',
  'Provedor de internet',
  'Hotelaria',
  'Saúde',
  'Prefeitura',
  'Shoppings',
  'Setor de beleza',
  'Outros',
];

export const getJobTypes = () => [
  'Analista de Marketing',
  'Analista de TI',
  'Diretor de Marketing',
  'Diretor de TI',
  'Empresário',
  'Outros',
];