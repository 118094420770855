import { connect } from 'react-redux';
import StepManager from '../components/StepManager';
import { fetchChangeStepIndex } from '../actions/stepActions';
import { openCloseTermOfUse } from '../actions/termOfUseAction';

const mapStateToProps = (state) => {
    const { currentStep, termOfUse } = state;
    return {
      currentStep,
      termOfUse,
    };
  }

const mapDispatchToProps = (dispatch) => {
    return {
      setStep: (stepIndex) => dispatch(fetchChangeStepIndex(stepIndex)),
      openCloseTermOfUse: (isOpen) => dispatch(openCloseTermOfUse(isOpen)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(StepManager);
