import React from 'react';
import TextField from 'material-ui/TextField';
import { grey700, grey500, grey300 } from 'material-ui/styles/colors';
import {logoColor} from '../helpers/colorsHelper';
import PropTypes from 'prop-types';

const InputField = (props) => {
    const {input, meta: {error, touched}, ...custom} = props;
    return (
        <TextField
            floatingLabelStyle={{color: grey500 }}
            underlineFocusStyle={{borderColor: logoColor,}}
            inputStyle={{color: grey700}}
            errorText={touched && error}
            hintStyle={{color: grey300}}
            {...input}
            {...custom}
        />    
    );
};

InputField.propTypes = {
    floatingLabelText: PropTypes.string,
    hintText: PropTypes.string,
    fullWidth: PropTypes.bool
};

export default InputField;
