import React, { Component } from 'react';
import ClientFisicoForm from '../ClientFisicoForm';
import ClientJuridicoForm from '../ClientJuridicoForm';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ClientTypeSwitch from '../ClientTypeSwitch';
import { PESSOA_FISICA, PESSOA_JURICIA } from '../../helpers/clientType';
import { SubmissionError } from 'redux-form';
import FooterMenu from '../FooterMenu';
import DialogUtil from '../DialogUtil';
import { CEP_LENGTH } from '../../helpers/validateHelper';
import * as ApiHelper from '../../helpers/ApiHelper';

class DataFormStep extends Component {

    state = {
        showResults: false,
        message: "",
        documentType: "cpf"
    }
    onChangeCep = (event, newCep, previousCep, name) => {
        const { loadAddress, storeCep } = this.props;

        if (newCep === storeCep) {
            return;
        }

        if (newCep && newCep.length === CEP_LENGTH) {
            loadAddress(newCep);
        }
    };

    onSelectClientType = (event, value) => {
        this.props.addClientType(value);
    };

    handlePrevStep = () => {
        const { currentStep, setStep } = this.props;
        if (currentStep > 0) {
            setStep(currentStep - 1);
        }
    };

    onSubmit = (values) => {
        const documentType = (this.props.clientType === 'pessoa_fisica') ? 'cpf' : 'cnpj';
        const document = (documentType === 'cpf') ? values.cpf : values.cnpj;
        const token = this.props.token;

        this.state.documentType = (this.props.clientType === 'pessoa_fisica') ? 'cpf' : 'cnpj';

        ApiHelper.checksCep(values)
            .then(response => {
                
            })
            .catch(err => {
                if (err.statusCode === 404) {
                    ApiHelper.logError({
                        level: 500,
                        message: err.response.body.message,
                        extra: values
                    });
                }
                throw err;
            });
        
        if(token){
            this.nextStepAction();
        }else{
            return this.checkInsertedDocument(document);
        }
    };

    checkInsertedDocument = (document) => {
        return ApiHelper.checkDocument(document)
            .then(isAvailable => {
                if(!isAvailable){
                    if (!isAvailable) {
                        throw new SubmissionError({ documentInUse: true, _error: true });
                    }
                }
                this.nextStepAction();
            })
            .catch(err => {
                if (err.errors.documentInUse === true) {
                    if (this.state.documentType === 'cpf') {
                        throw new SubmissionError({ cpf: 'CPF está em uso.', _error: true });
                    } else {
                        throw new SubmissionError({ cnpj: 'CNPJ está em uso.', _error: true });
                    }
                }
                this.props.addError("Falha ao criar a conta na Mambo. Retentando em 30 segundos")
                this.retryCheckDocument(document)
            });
    }

    nextStepAction = () =>{
        const { currentStep, setStep } = this.props;
        if (currentStep < 4) {
            setStep(currentStep + 1);
        }
    }

    retryCheckDocument = (document) => {
        setTimeout(() => {
            this.checkInsertedDocument(document);
        }, 30000);
    }

    getFormToRender = () => {
        const { clientType, isLoadingAddress } = this.props;

        switch (clientType) {
            case PESSOA_FISICA:
                return <ClientFisicoForm
                    {... this.props}
                    onChangeCep={this.onChangeCep}
                    isLoadingAddress={isLoadingAddress}
                    onCopyEmail={this.handleOnCopyEmailCheck}
                    onDocumentChange={this.onDocumentChange} />;

            case PESSOA_JURICIA:
                return <ClientJuridicoForm
                    {... this.props}
                    onChangeCep={this.onChangeCep}
                    isLoadingAddress={isLoadingAddress}
                    onCopyEmail={this.handleOnCopyEmailCheck}
                    onDocumentChange={this.onDocumentChange} />;
            default:
                return ""
        }
    };

    handleOnCopyEmailCheck = (event, newValue, previousValue, name) => {
        const { copyEmailToFinancialEmail, email } = this.props;
        if (newValue) {
            copyEmailToFinancialEmail(email);
        }
    };

    componentDidMount(){
        const { copyEmailToFinancialEmail, email, isCopyEmail } = this.props;
        if (isCopyEmail) {
            copyEmailToFinancialEmail(email);
        }
    }

    render() {
        const { currentStep, handleSubmit, pristine, valid, submitting, clientType } = this.props;
        return (
            <div>
                <ClientTypeSwitch
                    onChange={this.onSelectClientType}
                    clientType={clientType} />
                <form onSubmit={handleSubmit(this.onSubmit)}>
                    <Grid>
                        <Row>
                            <Col md={12}>
                                {this.getFormToRender()}
                            </Col>
                        </Row>
                    </Grid>
                    <FooterMenu
                        step={currentStep}
                        handlePrev={this.handlePrevStep}
                        isDisabledNext={!clientType || pristine || !valid || submitting}
                        style={{ marginTop: "2em", marginBottom: "2em" }}
                    />
                </form>
                {<DialogUtil
                    modal={false}
                    open={submitting}
                    text='Validando dados informados'
                />}

            </div>
        );
    }
}

export default DataFormStep;
