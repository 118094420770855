import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import Paper from 'material-ui/Paper';
import * as FontAwesome from 'react-icons/lib/fa'
import { logoColor } from '../helpers/colorsHelper';
import { grey500 } from 'material-ui/styles/colors';
import {
    VoucherField
} from '../helpers/formInputHelper';

const Describe = styled.div`
    color: ${grey500};
    line-height: 1.8em;
`;

class VoucherForm extends Component {
    render() {
        return (
            <Grid style={{marginTop: '2em'}}>
                <Row center='md'>
                    <Col md={8}>
                        <Paper style={{width: "100%", padding: '2em'}}>
                            <Grid>
                                <Row start='md'>
                                    <Col md={2} style={{marginTop: '2em'}}>
                                        <FontAwesome.FaTicket size={80} color={logoColor} />
                                    </Col>
                                    <Col md={8}>
                                        <Describe>
                                            <b>Possui cupom de desconto?</b>
                                            <VoucherField hintText="1234567890" />
                                        </Describe>
                                    </Col>
                                </Row>
                            </Grid>
                        </Paper>
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default VoucherForm;
