export const CHANGE_STEP_INDEX = "CHANGE_STEP_INDEX";

const changeStepIndex = step => {
    return {
        type: CHANGE_STEP_INDEX,
        step
    }
};

export const fetchChangeStepIndex = step => dispatch => {
    dispatch(changeStepIndex(step));
};
