import { connect } from 'react-redux';
import PurchasePage from '../../components/pages/PurchasePage';
import { fetchAddAllPlans } from '../../actions/planActions';
import { addClientToken } from '../../actions/clientAction';

const mapStateToProps = (state) => {
    const { loading } = state;
    return { 
        loading
    };
}
  
const mapDispatchToProps = (dispatch) => {
    return {
        getAllPlans: () => dispatch(fetchAddAllPlans()),
        addClientToken: (token) => dispatch(addClientToken(token))       
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PurchasePage);
