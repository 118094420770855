import React, { Component } from "react";
import Header from "../Header";
import StepManager from "../../containers/StepManager";
import ErrorAlert from "../../containers/ErrorAlert";
import qs from "qs";
import LoadProgress from "../LoadProgress";
import logoMamboBranco from '../../img/logo-branco.png';

class PurchasePage extends Component {
  componentDidMount() {
    const { token } = qs.parse(this.props.location.search.substr(1));

    this.props.getAllPlans();
    this.props.addClientToken(token);
  }
 
  render() {
    const { loading } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <ErrorAlert />
        <Header logo={logoMamboBranco} />
        {loading.isLoadingClientData || loading.isLoadingPlans ? (
          <LoadProgress />
        ) : (
          <StepManager {...this.props} />
        )}
      </div>
    );
  }
}

export default PurchasePage;
