import React from 'react';
import { FontawesomeHandORight, FontawesomeHandOLeft } from 'mui-icons/fontawesome';
import RaisedButton from 'material-ui/RaisedButton';
import { Grid, Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { logoColor, white } from '../helpers/colorsHelper';

const FooterMenu = (props) => {
    
    const {
        label,
        step,
        handlePrev,
        handleNext,
        isDisabledPrev,
        isDisabledNext,
        style,
    } = props;
    
    return (
        <Grid style={style}>
            <Row center="md">
                <Col md={6}>
                  <RaisedButton
                    onClick={handlePrev}
                    disabled={(step <= 0 || isDisabledPrev) ? true : false}
                    label="Voltar" 
                    style={{margin:"5px"}}
                    icon={<FontawesomeHandOLeft />} 
                    labelPosition="after" />

                  <RaisedButton
                    onClick={handleNext ? handleNext : undefined}
                    type={handleNext ? 'button' : 'submit'}
                    disabled={isDisabledNext}
                    backgroundColor={logoColor} 
                    labelColor={white} 
                    label={label ? label : "Avançar"}
                    style={{margin:"5px"}} 
                    icon={<FontawesomeHandORight />} 
                    labelPosition="before" />
                </Col>
            </Row>
        </Grid>
    );
}

FooterMenu.propTypes = {
    step : PropTypes.number,
    handlePrev : PropTypes.func.isRequired,
    handleNext : PropTypes.func,
    isDisabledNext: PropTypes.bool,
};

export default FooterMenu;