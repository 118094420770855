import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { grey400, grey600 } from 'material-ui/styles/colors';

const FieldBox = styled.div`
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0.8em;
`

const FieldLabel = styled.p`
    color: ${grey400};
    font-size: 0.8em;
    font-weigth: bolder;
    margin:0;
`;

const FieldContent = styled.p`
    color: ${grey600};
    font-size: 1em;
    line-height: 1.5em;
    margin:0;
`;

const FieldReviewBox = ({ label, content }) => (
    <FieldBox>
        <FieldLabel>{label}</FieldLabel>
        <FieldContent>{content}</FieldContent>
    </FieldBox>
);

FieldReviewBox.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
}

export default FieldReviewBox;
