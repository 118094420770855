import { SET_NUMBER_OF_APS } from '../actions/numberOfApsAction';

export const numberOfAps = (state = 1, action) => {
    switch (action.type) {
        case SET_NUMBER_OF_APS:
            return action.total;
        default:
            return state;
    }
};
