import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { logoColor } from '../helpers/colorsHelper';
import styled from 'styled-components';
import { grey500 } from 'material-ui/styles/colors';

const InfoMessage = styled.p`
    color: ${grey500};
    text-align: center;
    margin-top: 1em;
`;

const LoadProgress = () => (
    <Grid>
        <Row center='md'>
            <Col md={7}>
                <CircularProgress size={80} thickness={5} color={logoColor} style={{marginTop: '8em'}} />
                <InfoMessage>Carregando, aguarde.</InfoMessage>
            </Col>
        </Row>
    </Grid>
);

export default LoadProgress;