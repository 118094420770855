import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { grey500, grey400 } from 'material-ui/styles/colors';
import { logoColor } from '../../helpers/colorsHelper';
import Slider from 'material-ui/Slider';
import * as PlanHelper from '../../helpers/PlanHelper';
import * as PriceHelper from '../../helpers/PriceHelper';
import AnualPlanCard from '../AnualPlanCard';
import MonthPlanCard from '../MonthPlanCard';
import SalesContactCard from '../SalesContactCard';
import FooterMenu from '../../components/FooterMenu';
import MinusIcon from 'mui-icons/cmdi/minus-circle-outline';
import PlusIcon from 'mui-icons/cmdi/plus-circle-outline';
import GestureIcon from 'mui-icons/cmdi/gesture-double-tap';
import ArrowLeftIcon from 'mui-icons/cmdi/arrow-left-thick';
import ArrowRightIcon from 'mui-icons/cmdi/arrow-right-thick';
import IconButtom from 'material-ui/IconButton';
import LoadProgress from '../LoadProgress';

const SLIDER_STEP = 1;

const LabelTotalAps = styled.p`
  text-align: center;
  font-weight: bolder;
`;

const NumberSpan = styled.span`
  font-size: 1em;
`;

const Condition = styled.p`
  color: ${grey500};
  text-align: center;
`;

const SlideLabel = styled.p`
  color: ${grey500};
  text-align: center;
`;

class SelectPlanStep extends Component {
  handleSliderChange = (event, value) => {
    const { setNumberOfAps } = this.props;
    setNumberOfAps(value);
  };

  handlePlusApButtom = () => {
    const { numberOfAps, setNumberOfAps, plans } = this.props;
    const totalOfPlans =
      plans[PlanHelper.PLANOS_ANUAIS].length > 20
        ? 21
        : plans[PlanHelper.PLANOS_ANUAIS].length + 1;

    if (numberOfAps < totalOfPlans) {
      setNumberOfAps(numberOfAps + 1);
    }
  };

  handleMinusApButtom = () => {
    const { numberOfAps, setNumberOfAps } = this.props;

    if (numberOfAps > 1) {
      setNumberOfAps(numberOfAps - 1);
    }
  };

  getTotalOfPlans = () => {
    const {
      plans,
      loading: { isLoadingPlans },
    } = this.props;
    if (isLoadingPlans) return 0;
    let totalOfPlans = plans[PlanHelper.PLANOS_ANUAIS].length;
    // Plus 1 to overload the number of app and show comercial contact
    return totalOfPlans > 20 ? 21 : totalOfPlans + 1;
  };

  handlePrevStep = () => {
    const { currentStep, setStep } = this.props;
    if (currentStep > 0) {
      setStep(currentStep - 1);
    }
  };

  handleNextStep = () => {
    const { currentStep, setStep } = this.props;
    if (currentStep < 3) {
      setStep(currentStep + 1);
    }
  };

  renderSliderRow = () => {
    const { numberOfAps } = this.props;
    return (
      <div>
        <Row center="md">
          <Col md={10}>
            <SlideLabel>
              Selecione abaixo a quantidade de pontos de acesso que deseja
              contratar
            </SlideLabel>
          </Col>
        </Row>
        <Row start="md">
          <Col md={1}>
            <IconButtom onClick={this.handleMinusApButtom}>
              <MinusIcon color={logoColor} />
            </IconButtom>
          </Col>
          <Col md={10}>
            <Slider
              max={this.getTotalOfPlans()}
              min={1}
              step={SLIDER_STEP}
              value={numberOfAps}
              defaultValue={1}
              onChange={this.handleSliderChange}
              sliderStyle={{ marginBottom: '0.1em', marginTop: '1em' }}
            />
          </Col>
          <Col md={1}>
            <IconButtom onClick={this.handlePlusApButtom}>
              <PlusIcon color={logoColor} />
            </IconButtom>
          </Col>
        </Row>
        <Row center="md">
          <Col md={1}>
            <IconButtom onClick={this.handleMinusApButtom}>
              <ArrowLeftIcon color={grey400} />
            </IconButtom>
          </Col>
          <Col md={1}>
            <IconButtom>
              <GestureIcon color={grey400} />
            </IconButtom>
          </Col>
          <Col md={1}>
            <IconButtom onClick={this.handlePlusApButtom}>
              <ArrowRightIcon
                color={grey400}
                onClick={this.handlePlusApButtom}
              />
            </IconButtom>
          </Col>
        </Row>
      </div>
    );
  };

  renderLabelEquipamentsNumberRow = () => {
    const { numberOfAps } = this.props;
    const totalAps = this.isExceedNumberOfAps()
      ? `+${numberOfAps}`
      : numberOfAps;
    return (
      <Row>
        <Col md={12}>
          <LabelTotalAps
            style={
              numberOfAps === 0 ? { color: grey500 } : { color: logoColor }
            }
          >
            <NumberSpan>{totalAps}</NumberSpan>{' '}
            {numberOfAps === 1
              ? 'Ponto de acesso selecionado'
              : 'Pontos de acesso selecionados'}
          </LabelTotalAps>
        </Col>
      </Row>
    );
  };

  renderPlansCardDisplayRow = () => {
    const { numberOfAps, plans, selectPlanType, planType } = this.props;

    const selectedPlanMonthly = PlanHelper.getMonthlyPlanByIndex(
      numberOfAps,
      plans,
    );

    const selectedPlanYearly = PlanHelper.getYearlyPlanByIndex(
      numberOfAps,
      plans,
    );

    const yearlyDiscountPercentage = PriceHelper.calcDiscountInPercent(
      selectedPlanMonthly.price,
      selectedPlanYearly.price / 12,
    );

    const montlyIncrementPercentage = PriceHelper.calcDiscountInPercent(
      selectedPlanYearly.price,
      selectedPlanMonthly.price * 12,
    );

    return (
      <Row>
        <Col md={6}>
          <MonthPlanCard
            onSelectPlanType={selectPlanType}
            percentage={montlyIncrementPercentage}
            plan={selectedPlanMonthly}
            title="Plano Mensal"
            subtitle="Valor por mês"
            isSelected={planType === PlanHelper.MODE_MONTHLY}
            planType={PlanHelper.MODE_MONTHLY}
          />
        </Col>
        <Col md={6}>
          <AnualPlanCard
            onSelectPlanType={selectPlanType}
            percentage={yearlyDiscountPercentage}
            plan={selectedPlanYearly}
            title="Plano Anual"
            subtitle="Valor total pago à vista"
            isSelected={planType === PlanHelper.MODE_YEARLY}
            planType={PlanHelper.MODE_YEARLY}
          />
        </Col>
        <Col md={12}></Col>
      </Row>
    );
  };

  renderExceedApNumberSalesContact = () => {
    const { numberOfAps } = this.props;

    return (
      <Row style={{ marginBottom: '1em' }}>
        <Col md={12}>
          <SalesContactCard numberOfAps={numberOfAps} />
        </Col>
      </Row>
    );
  };

  renderContentWithPlansLoaded = () => {
    const { currentStep } = this.props;
    const isExceedNumberOfAps = this.isExceedNumberOfAps();
    const token = this.props.client.token;

    return (
      <Grid>
        <Row center="md">
          <Col md={7}>
            {this.renderSliderRow()}
            {this.renderLabelEquipamentsNumberRow()}
            {isExceedNumberOfAps
              ? this.renderExceedApNumberSalesContact()
              : this.renderPlansCardDisplayRow()}
          </Col>
        </Row>
        <Row center="md">
          <Col md={7}>
            <Row>
              <Col md={12}>
                {isExceedNumberOfAps ? (
                  ''
                ) : (
                  <Condition>
                    * O "PLANO MENSAL" é cobrado mensalmente o valor informado.
                    <br />
                    * O "PLANO ANUAL" é efetuada uma única cobrança no valor
                    total, este plano não é possível parcelar.
                    <br />* Valor referente apenas a assinatura do serviço, não
                    inclui equipamento.
                  </Condition>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FooterMenu
                  step={currentStep}
                  handleNext={this.handleNextStep}
                  handlePrev={this.handlePrevStep}
                  isDisabledPrev={token ? true : false}
                  isDisabledNext={this.isExceedNumberOfAps()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    );
  };

  isExceedNumberOfAps = () => {
    const { numberOfAps, plans } = this.props;
    let numberOfPlans = plans[PlanHelper.PLANOS_ANUAIS].length;
    return numberOfAps > (numberOfPlans > 20 ? 20 : numberOfPlans);
  };

  render() {
    const { isLoadingPlans } = this.props;
    return isLoadingPlans ? (
      <LoadProgress />
    ) : (
      this.renderContentWithPlansLoaded()
    );
  }
}

export default SelectPlanStep;
