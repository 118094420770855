import {
    domainValidate,
    companyNameValidate,
    nameValidate,
    phoneValidate,
    jobAreaValidate,
    jobTypeValidate,
    emailValidate,
    passwordMatchValidate,
    termOfUseValidate,
} from './validateHelper';

export const syncValidation = (values) => {
    const errors = {};
    const { 
        domain,
        company,
        name,
        phone,
        jobArea,
        jobType,
        email,
        password,
        confirmPassword,
        termOfUse,
    } = values;

    domainValidate(domain, errors);
    companyNameValidate(company, errors);
    nameValidate(name, errors);
    phoneValidate(phone, errors);
    jobAreaValidate(jobArea, errors);
    jobTypeValidate(jobType, errors);
    emailValidate(email, errors);
    passwordMatchValidate(password, confirmPassword, errors);
    termOfUseValidate(termOfUse, errors);

    return errors;
};
