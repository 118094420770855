import React, { Component } from 'react';
import { 
    CHECK_EMAIL_DOMAIN_STEP,
    SELECT_PLAN_STEP,
    FILL_FORM_STEP,
    PAYMENT_INFO_STEP,
    CONFIRM_INFO_STEP,
} from '../helpers/stepHelper';
import TermOfUse from '../containers/TermOfUse';
import HeaderSteper from '../components/HeaderSteper';

import CheckEmailDomainStep from '../containers/steps/CheckEmailDomainStep';
import SelectPlanStep from '../containers/steps/SelectPlanStep';
import DataFormStep from '../containers/steps/DataFormStep';
import PaymentInfoStep from '../containers/steps/PaymentInfoStep';
import ConfirmationStep from '../containers/steps/ConfirmationStep';

class StepManager extends Component {
    
    handleStep = (step) => {
        const {setStep} = this.props;
        setStep(step);
    }

    getStepContent = (step) => {
        switch(step) {
            case CHECK_EMAIL_DOMAIN_STEP:
                return <CheckEmailDomainStep />;
            case SELECT_PLAN_STEP:
              return <SelectPlanStep />
            case FILL_FORM_STEP:
                return <DataFormStep />;
            case PAYMENT_INFO_STEP:
                return <PaymentInfoStep />
            case CONFIRM_INFO_STEP:
                return <ConfirmationStep {...this.props} />;
            default:
                return <p>Step not found</p>;
        }
    }

    renderStepPage = () => {
        const { currentStep } = this.props;
        const stepContent = this.getStepContent(currentStep);
        return (
            <div>
                <HeaderSteper 
                    currentStep={currentStep}
                    handleStep={this.handleStep} />
                    
                {stepContent}
            </div>
        )
    }
    
    render() {
        const { termOfUse } = this.props;
        
        return (
            <div>
                {termOfUse.isOpen 
                    ? <TermOfUse  />
                    : this.renderStepPage() }
                
            </div>
        )
    }
}

export default StepManager;
