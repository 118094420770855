import React from 'react';
import CheckIcon from 'mui-icons/cmdi/verified';
import PencilIcon from  'mui-icons/cmdi/pencil-box-outline';
import CoinIcon from  'mui-icons/cmdi/coin';
import CreditCardIcon from  'mui-icons/cmdi/credit-card';
import ListCheckIcon from  'mui-icons/cmdi/format-list-checks';
import { logoColor } from './colorsHelper';
import { grey400 } from 'material-ui/styles/colors';

export const CHECK_EMAIL_DOMAIN_STEP = 0;
export const SELECT_PLAN_STEP = 1;
export const FILL_FORM_STEP = 2;
export const PAYMENT_INFO_STEP = 3;
export const CONFIRM_INFO_STEP = 4;

export const getStepInfo = (step, currentStep) => {
    switch (step) {
        case CHECK_EMAIL_DOMAIN_STEP:
            return {
                label: "Verificar domínio e e-mail",
                icon: <ListCheckIcon color={currentStep === CHECK_EMAIL_DOMAIN_STEP ? logoColor : grey400} />,
                style: currentStep === CHECK_EMAIL_DOMAIN_STEP 
                    ? {color: logoColor, cursor: 'default'}
                    : {color: grey400, cursor: 'default'}
                };
        case SELECT_PLAN_STEP:
            return {
                label: "Selecione o plano",
                icon: <CoinIcon color={currentStep === SELECT_PLAN_STEP ? logoColor : grey400} />,
                style: currentStep === SELECT_PLAN_STEP 
                    ? {color: logoColor, cursor: 'default'}
                    : {color: grey400, cursor: 'default'}
            };
        case PAYMENT_INFO_STEP:
            return {
                label: "Forma de pagamento",
                icon: <CreditCardIcon color={currentStep === PAYMENT_INFO_STEP ? logoColor : grey400} />,
                style: currentStep === PAYMENT_INFO_STEP 
                    ? {color: logoColor, cursor: 'default'}
                    : {color: grey400, cursor: 'default'}
            }
        case FILL_FORM_STEP:
            return {
                label: "Preencher Cadastro",
                icon: <PencilIcon color={currentStep === FILL_FORM_STEP ? logoColor : grey400} />,
                style: currentStep === FILL_FORM_STEP
                    ? {color: logoColor, cursor: 'default'}
                    : {color: grey400, cursor: 'default'}
            };
        case CONFIRM_INFO_STEP:
            return {
                label: "Confirmar dados",
                icon: <CheckIcon color={currentStep === CONFIRM_INFO_STEP ? logoColor : grey400} />,
                style: currentStep === CONFIRM_INFO_STEP
                    ? {color: logoColor, cursor: 'default'}
                    : {color: grey400, cursor: 'default'}
            };
        default:
            return {
                label: "",
                icon: null
            };
    }
};
