import React, { Component } from 'react';
import Header from '../Header';
import ErrorAlert from '../../containers/ErrorAlert';
import TrialForm from '../TrialForm';
import DialogUtil from '../DialogUtil';
import { SubmissionError } from 'redux-form';
import * as ClientHelper from '../../helpers/ClientHelper';
import TermOfUse from '../../containers/TermOfUse';
import logoMamboBranco from '../../img/logo-branco.png';

class TrialPage extends Component {
  onSubmit = (values) => {
    const domain = values.domain;
    const email = values.email;
    const password = values.password;
    const environment = process.env.REACT_APP_API_REDIRECT_ENVIRONMENT;
    const redirectUrl = `http://${domain}.${environment}/admin/login?email=${email}&pwd=${password}&domain=${domain}`;

    this.props.addRedirectUrl(redirectUrl);

    return ClientHelper.createTrial(values)
      .then(result => {
        this.props.history.push('/redirecting');
      })
      .catch((err) => {
        if (err instanceof SubmissionError) {
          throw err;
        } else {
          this.props.addError(err.message);
        }
      });
  };

  render() {
    const { handleSubmit, submitting, openCloseTermOfUse, termOfUse } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <ErrorAlert />
        <Header logo={logoMamboBranco}/>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          {termOfUse.isOpen
            ? <TermOfUse />
            : <TrialForm openCloseTermOfUse={openCloseTermOfUse} />}

          <DialogUtil
            modal={false}
            open={submitting}
            text='Aguarde... Estamos validando seus dados e criando sua conta de teste.'
          />

        </form>
      </div>
    )
  }
}

export default TrialPage;
