import React from 'react';
import Divider from 'material-ui/Divider';
import { grey200 } from 'material-ui/styles/colors';

const ReviewDivider = () => (
    <div style={{paddingLeft: '1em', paddingRight: '1em', marginBottom: "0"}}>
        <Divider style={{backgroundColor: grey200}} />
    </div>
);

export default ReviewDivider;
