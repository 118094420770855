import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardActions, CardTitle, CardText} from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import styled from 'styled-components';
import { grey100, grey300, grey500, green500 } from 'material-ui/styles/colors';
import { logoColor } from '../helpers/colorsHelper';
import CheckAllIcon from 'mui-icons/cmdi/check-all';
import * as PriceHelper from '../helpers/PriceHelper';

const unselectColors = {
    title: {
        color: grey300,
    },
    subtitle: {
        color: grey300,
    },
    price: {
        color: grey300,
    },
    buttonBgColor: {
        color: grey100
    },
    buttonLabelColor: {
        color: grey500
    }
};

const selectedColors = {
    title: {
        color: logoColor,
    },
    subtitle: {
        color: grey500,
    },
    price: {
        color: green500,
    },
    buttonBgColor: {
        color: logoColor
    },
    buttonLabelColor: {
        color: 'white'
    }
}

const BadgeDiscount = styled.div`
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    position: absolute;
    top: -1em;
    right: -1em;
    background-color: ${green500};
    color: #fff;
    border-radius: 50%;
`;

const styles = {
    yearlyPrice: {
        position: 'relative',
        bottom: '1.5em',
        height: '0.5em'
    },
    buttonAction: {
        position: 'relative',
        bottom: '1em'
    }
}

const MonthPlanCard = (props) => {
    const { isSelected, planType, title, subtitle, plan, percentage, onSelectPlanType } = props;
    const monthPrice = plan.price;

    return (
        <Card style={{position: 'relative'}}>
            {percentage.result > 0 && <BadgeDiscount>{percentage.label}</BadgeDiscount>}

            <CardTitle 
                title={title}
                subtitle={subtitle} 
                titleColor={isSelected 
                    ? selectedColors.title.color
                    : unselectColors.title.color}
                subtitleColor={isSelected 
                    ? selectedColors.subtitle.color 
                    : unselectColors.subtitle.color}/>
            <CardText 
                color={isSelected 
                    ? selectedColors.price.color 
                    : unselectColors.price.color} 
                style={{ fontSize: '2em', fontWeight: 'bolder' }}>
                {`${PriceHelper.parsePrice(monthPrice)}*`}
            </CardText>
            <CardTitle 
                style={styles.yearlyPrice}
                subtitle=""
                subtitleColor={isSelected 
                    ? selectedColors.subtitle.color 
                    : unselectColors.subtitle.color}/>
            <CardActions>
              <FlatButton
                style={styles.buttonAction}
                onClick={() => onSelectPlanType(planType)}
                label={ isSelected ? "Selecionado" : "Selecionar"}
                backgroundColor={isSelected 
                    ? selectedColors.buttonBgColor.color 
                    : unselectColors.buttonBgColor.color} 
                labelStyle={{
                    color: isSelected 
                        ? selectedColors.buttonLabelColor.color
                        : unselectColors.buttonLabelColor.color
                }}
                hoverColor='red'
                icon={isSelected 
                    ? <CheckAllIcon color="#fff" /> 
                    : null} />
            </CardActions>
        </Card>
    );
}

MonthPlanCard.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    planType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    plan: PropTypes.object.isRequired,
    percentage: PropTypes.object.isRequired,
    onSelectPlanType: PropTypes.func.isRequired,
}

export default MonthPlanCard;
