import React, { Component } from 'react';
import Header from '../Header';
import TrialOrPuschase from '../TrialOrPurchase';
import logoMambo from '../../img/logo-mambo.png';

class HomePage extends Component {

  onClickRedirect = (route) => {
		const { history } = this.props;
		history.push(route);
	}
  
  render() {
    console.log('Implantacao versao 20190131.1');
    return (
      <div style={{height: "100%"}}>
        <Header bgColor='#fff' logo={logoMambo} />
        <TrialOrPuschase onClickRedirect={this.onClickRedirect} />
      </div>
    )
  }
}

export default HomePage;