export const IS_OPEN_TERM_OF_USE = "IS_OPEN_TERM_OF_USE";
export const SET_NUMBER_OF_PAGES = "SET_NUMBER_OF_PAGES";
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export const openCloseTermOfUse = (isOpen) => ({
    type: IS_OPEN_TERM_OF_USE,
    isOpen,
});

export const setNumberOfPages = (number) => ({
    type: SET_NUMBER_OF_PAGES,
    number,
});

export const setPageNumber = (number) => ({
    type: SET_PAGE_NUMBER,
    number,
});
