import { SELECT_PLAN_TYPE } from '../actions/planTypeAction';
import * as PlanHelper from '../helpers/PlanHelper';

export const planType = (state = PlanHelper.MODE_YEARLY, action) => {
    switch (action.type) {
        case SELECT_PLAN_TYPE:
            return action.planType;
        default:
            return state;
    }
}
