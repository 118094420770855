import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import TrialOrPurchaseCard from './TrialOrPurchaseCard';
import PropTypes from 'prop-types';
import { CARD_TYPE_TRIAL, CARD_TYPE_PURCHASE } from '../helpers/homeHelper';
import { grey500 } from 'material-ui/styles/colors';
import { logoColor } from '../helpers/colorsHelper';
import styled from 'styled-components';

const Contact = styled.p`
  color: ${grey500};
`;

const Span = styled.span`
  color: ${logoColor};
`;

const TrialOrPurchase = ({ onClickRedirect }) => (
  <Grid style={{ paddingTop: '2em', paddingBottom: '0.5em' }}>
    <Row center="md">
      <Col md={4}>
        <TrialOrPurchaseCard
          title="Quero testar"
          subtitle="7 dias grátis"
          type={CARD_TYPE_TRIAL}
          buttonLabel="Iniciar teste"
          buttonUrl="/trial"
          onClickRedirect={onClickRedirect}
        />
      </Col>

      <Col md={4}>
        <TrialOrPurchaseCard
          title="Quero contratar"
          subtitle="Cadastre-se e aproveite"
          type={CARD_TYPE_PURCHASE}
          buttonLabel="Contratar agora"
          buttonUrl="/purchase"
          onClickRedirect={onClickRedirect}
        />
      </Col>
    </Row>
    <Row center="md" style={{ marginTop: '2em' }}>
      <Col md={12}>
        <Contact>
          Qualquer dúvida estamos a disposição pelos contatos{' '}
          <Span>(19) 3113-2444</Span> ou <Span>comercial@mambowifi.com</Span>
        </Contact>
        <Contact>
          Mambo WiFi -{' '}
          <a
            href="https://www.mambowifi.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.mambowifi.com
          </a>
        </Contact>
      </Col>
    </Row>
  </Grid>
);

TrialOrPurchase.propTypes = {
  onClickRedirect: PropTypes.func.isRequired,
};

export default TrialOrPurchase;
