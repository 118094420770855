import { connect } from 'react-redux';
import { reduxForm, formValueSelector, getFormMeta } from 'redux-form';
import PaymentInfoStep from '../../components/steps/PaymentInfoStep';
import { addPaymentType } from '../../actions/paymentAction';
import { getCreditCardFocus } from '../../helpers/creditCardHelper';
import { syncValidation } from '../../helpers/validateHelper';
import { fetchChangeStepIndex } from '../../actions/stepActions';
import { loadBillingAddress, copyAddressToBillingAddress } from '../../actions/addressAction';
import {
    addVoucher,
    processCardBrandAndGoNextStep
} from '../../actions/paymentAction';

const selector = formValueSelector('wizard');
const formMeta = getFormMeta('wizard');

const mapStateToProps = (store) => {
    const { payment, currentStep, loading, client } = store;
    let cardFocusOn = getCreditCardFocus(formMeta(store));
    
    const {
        monthValidityCreditCard,
        yearValidityCreditCard,
        creditCardCvv,
        creditCardNumber, 
        creditCardName,
        isCopyBillingAddress,
        cep,
        address,
        addressNumber,
        district,
        city,
        state,
        addressComplement,
        voucher,
    } = selector(
        store,
        'monthValidityCreditCard',
        'yearValidityCreditCard',
        'creditCardCvv',
        'creditCardNumber',
        'creditCardName',
        'isCopyBillingAddress',
        'cep',
        'address',
        'addressNumber',
        'district',
        'city',
        'state',
        'addressComplement',
        'voucher',
    );

    return {
        storeBillingCep: client.billingCep,
        isCopyBillingAddress,
        isLoadingBillingAddress: loading.isLoadingBillingAddress,
        currentStep,
        paymentType: payment.type,
        cardFocusOn,
        monthValidityCreditCard: monthValidityCreditCard ? monthValidityCreditCard : '00',
        yearValidityCreditCard: yearValidityCreditCard ? yearValidityCreditCard : '00',
        creditCardCvv: creditCardCvv ? creditCardCvv : '000',
        creditCardNumber: creditCardNumber ? creditCardNumber : '0000000000000000', 
        creditCardName: creditCardName? creditCardName : "Tony stark",
        address: {
            cep,
            address,
            addressNumber,
            district,
            city,
            state,
            addressComplement,
        },
        voucher,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addVoucher: (voucher) => dispatch(addVoucher(voucher)),
        addPaymentType: (paymentType) => dispatch(addPaymentType(paymentType)),
        setStep: (stepIndex) => dispatch(fetchChangeStepIndex(stepIndex)),
        loadBillingAddress: (cep) => dispatch(loadBillingAddress(cep)),
        copyAddressToBillingAddress: (address) => dispatch(copyAddressToBillingAddress(address)),
        processCardBrandAndGoNextStep: (cardBrand, nextStep) => dispatch(processCardBrandAndGoNextStep(cardBrand, nextStep)),
    }
};

const FormComponent = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: syncValidation,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(PaymentInfoStep);

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
