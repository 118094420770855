import * as moment from 'moment';
import * as _ from 'lodash';

export const MAX_YEARS = 10;
export const getMonthValidity = () => ['01', '02', '03', '04', '05', '06', '07', '08', '09','10', '11', '12'];

export const getYearValidity = (totalYears) => {
    const years = [];
    const now = moment()
    
    for(let i = 0; i < totalYears; i++) {
        years.push(now.format('Y'));
        now.add(1, 'years');
    }

    return years;
};

export const getCreditCardFocus = (formMeta) => {
    let focusOn = 'expiry';
    Object
        .keys(formMeta)
        .forEach(fieldName => {
            const field = formMeta[fieldName];
            if (_.has(field, 'active')) {
                focusOn = fieldNameTranslateToCard(fieldName);
            }
        });
    return focusOn;
}

const fieldNameTranslateToCard = (fieldName) => {
    const cardLabel = {
        monthValidityCreditCard: 'expiry',
        yearValidityCreditCard: 'expiry',
        creditCardCvv: 'cvc',
        creditCardNumber: 'number',
        creditCardName: 'name',
    }

    return cardLabel[fieldName] ? cardLabel[fieldName] : 'expiry';
}
