import { connect } from 'react-redux';
import ErrorAlert from '../components/ErrorAlert';

const mapStateToProps = (state) => {
    const { error } = state;
    return {
      error,
    };
  }
  
const mapDispatchToProps = (dispatch) => {
    return {
      
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
