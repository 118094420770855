import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomePage from './containers/pages/HomePage';
import PurchasePage from './containers/pages/PurchasePage';
import TrialPage from './containers/pages/TrialPage';
import RedirectPage from './containers/pages/RedirectPage';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import reducer from './reducers';
import RouteError from './containers/pages/RouteError';
import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider>
      <Provider store={store}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/trial" component={TrialPage} />
          <Route path="/purchase" component={PurchasePage} />
          <Route path="/redirecting" component={RedirectPage} />
          <Route path="/error" component={RouteError} />
        </Switch>
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>
  , document.getElementById('root')
);

unregisterServiceWorker();
