import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { grey200, grey600 } from 'material-ui/styles/colors';
import { logoColor } from '../helpers/colorsHelper';

const FieldBox = styled.div`
    
    margin-bottom: 0.8em;
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`

const FieldLabel = styled.p`
    color: ${logoColor};
    font-size: 0.9em;
    font-weigth: bolder;
    line-height: 2.5em;
    text-align: center;
    margin:0;
`;

const FieldContent = styled.p`
    color: ${grey600};
    font-size: 0.8em;
    line-height: 1.5em;
    margin:0;
`;

const Divider = styled.hr`
    border: 1px dashed ${grey200};
`;

const TipBox = ({ label, content, icon }) => {
    return (
    <FieldBox>
        <FieldLabel>{icon} {label}</FieldLabel>
        <Divider />
        <FieldContent>{content}</FieldContent>
    </FieldBox>
    );
};

TipBox.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
}

export default TipBox;
