export const PLANOS_ANUAIS = 'Planos Anuais (Mambo)';
export const PLANOS_MENSAIS = 'Planos Mensais (Mambo)';
export const MODE_YEARLY = 'yearly';
export const MODE_MONTHLY = 'monthly';

export const getMonthlyPlanByIndex = (index, plans) => {
  const plan = plans[PLANOS_MENSAIS].filter((plan) => {
    return Number.parseInt(plan.name, 10) === index;
  });
  return plan[0];
};

export const getYearlyPlanByIndex = (index, plans) => {
  const plan = plans[PLANOS_ANUAIS].filter((plan) => {
    return Number.parseInt(plan.name, 10) === index;
  });
  return plan[0];
};

export const getPlan = (planType, numberOfAps, plans) => {
  const planObj = {
    id: '',
    name: '',
    description: '',
    price: '',
  };

  const type = planType === 'monthly' ? 'Planos Mensais' : 'Planos Anuais';
  const planList = plans[type];
  return planList && planList.length > 0 ? planList[numberOfAps - 1] : planObj;
};
