
export const ADD_ERROR_MESSAGE="ADD_ERROR_MESSAGE";
export const REMOVE_ERROR_MESSAGE="REMOVE_ERROR_MESSAGE";

export const addError = (message) => ({
    type: ADD_ERROR_MESSAGE,
    message
});

export const removeError = () => ({
    type: REMOVE_ERROR_MESSAGE
});
