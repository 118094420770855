import { 
    ADD_IS_LOADING_PLANS,
    REMOVE_IS_LOADING_PLANS,
    ADD_IS_LOADING_CLIENT_DATA,
    REMOVE_IS_LOADING_CLIENT_DATA,
    ADD_IS_LOADING_ADDRESS,
    REMOVE_IS_LOADING_ADDRESS,
    ADD_IS_LOADING_BILLING_ADDRESS,
    REMOVE_IS_LOADING_BILLING_ADDRESS,
} from '../actions/loadingActions';

const defaultState = {
    isLoadingPlans: false,
    isLoadingClientData: false,
    isLoadingAddress: false,
    isLoadingBillingAddress: false,
};

export const loading = (state = defaultState, action) => {
    switch (action.type) {
        case ADD_IS_LOADING_PLANS:
            return {
                ...state,
                isLoadingPlans: true,
            }
        case REMOVE_IS_LOADING_PLANS:
            return {
                ...state,
                isLoadingPlans: false,
            }
        case ADD_IS_LOADING_CLIENT_DATA:
            return {
                ...state,
                isLoadingClientData: true
            }
        case REMOVE_IS_LOADING_CLIENT_DATA:
            return {
                ...state,
                isLoadingClientData: false
            }
        case ADD_IS_LOADING_ADDRESS:
            return {
                ...state,
                isLoadingAddress: true
            }
        case REMOVE_IS_LOADING_ADDRESS:
            return {
                ...state,
                isLoadingAddress: false
            }
        case ADD_IS_LOADING_BILLING_ADDRESS: 
            return {
                ...state,
                isLoadingBillingAddress: true
            }
        case REMOVE_IS_LOADING_BILLING_ADDRESS:
            return {
                ...state,
                isLoadingBillingAddress: false
            }
        default:
            return state;
    }
};
