import { getClientDataByToken } from '../helpers/ApiHelper';
import { addError } from './errorActions';
import { addIsLoadingClientData, removeIsLoadingClientData } from './loadingActions';
import { change } from 'redux-form';
import { fetchChangeStepIndex } from './stepActions';

export const ADD_CLIENT_TYPE = 'ADD_CLIENT_TYPE';
export const ADD_TOKEN = 'ADD_TOKEN';
export const ADD_CLIENT_DATA = 'ADD_CLIENT_DATA';
export const ADD_CEP = 'ADD_CEP';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const ADD_BILLING_CEP = 'ADD_BILLING_CEP';
export const ADD_BILLING_ADDRESS = 'ADD_BILLING_ADDRESs';
export const ADD_REDIRECT_URL = 'ADD_REDIRECT_URL';

export const addClientType = (clientType) => ({
    type: ADD_CLIENT_TYPE,
    clientType,
});

export const addToken = (token) => ({
    type: ADD_TOKEN,
    token,
});

export const addClientData = (clientData) => ({
    type: ADD_CLIENT_DATA,
    clientData,
});

export const addCep = (cep) => ({
    type: ADD_CEP,
    cep,
});

export const addAddress = (address) => ({
    type: ADD_ADDRESS,
    address
});

export const addBillingCep = (cep) => ({
    type: ADD_BILLING_CEP,
    cep,
});

export const addBillingAddress = (address) => ({
    type: ADD_BILLING_ADDRESS,
    address,
})

export const addClientToken = token => dispatch => {
    if (token) {
        dispatch(addIsLoadingClientData());

        getClientDataByToken(token)
            .then(clientData => {
                dispatch(addToken(token));
                dispatch(addClientData(clientData));
                dispatch(change('wizard', 'email', clientData.admin.email));
                dispatch(change('wizard', 'financialEmail', clientData.admin.email));
                dispatch(change('wizard', 'domain', clientData.domain));
                dispatch(change('wizard', 'name', clientData.admin.fullName));
                dispatch(change('wizard', 'company', clientData.company.name));
                dispatch(removeIsLoadingClientData());
                dispatch(fetchChangeStepIndex(1));
            })
            .catch(err => {
                dispatch(addError('Falha ao consultar as informações da sua conta. Tente novamente.'))
            });
    }
}

export const addRedirectUrl = (redirectUrl) => ({
    type: ADD_REDIRECT_URL,
    redirectUrl,
})
