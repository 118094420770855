export function calcDv(digits = []) {
  const ehDigito = val => /^\d$/.test(val);

  const ehArr = Array.isArray(digits);
  const ehStr = typeof digits === "string";

  const ehTipoValido = ehArr || ehStr;
  const saoTdsDigitos = Array.from(digits).every(ehDigito);
  const temNoveDigitos = digits.length === 9;

  const ehNumValido = ehTipoValido && saoTdsDigitos && temNoveDigitos;
  if (!ehNumValido) throw new Error("invalid digits");

  const fazerSomatoria = digitos => {
    const somar = (a, b) => a + b;
    const calcular = (num, i) => num * (9 - (i % 10));

    const dv = digitos
      .map(calcular)
      .reduce(somar);

    return dv;
  };

  const digitosEmArr = ehStr
    ? digits.split("")
    : digits;

  const digitosInvertidos = digitosEmArr
    .concat()
    .reverse();

  const somatoria1 = fazerSomatoria(digitosInvertidos);
  const somatoria2 = fazerSomatoria([0].concat(digitosInvertidos));

  const v = [];

  v[0] = somatoria1 % 11 % 10;
  v[1] = (somatoria2 + v[0] * 9) % 11 % 10;

  return v;
}

export function clear(cpf = "") {
    const ehStr = typeof cpf === "string";
    if (!ehStr) throw new Error("invalid CPF number");
  
    const N_DIGITOS = /\D/g;
    const NADA = "";
  
    const cpfLimpo = cpf.replace(N_DIGITOS, NADA);
  
    return cpfLimpo;
}

export function format(cpf = "") {
  const ehStr = typeof cpf === "string";
  if (!ehStr) throw new Error("invalid CPF number");

  const cpfLimpo = clear(cpf);

  const temTamanhoValido = cpfLimpo.length === 11;
  if (!temTamanhoValido) throw new Error("invalid CPF number");

  const REGEX = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  const MASCARA = "$1.$2.$3-$4";

  const cpfFormatado = cpfLimpo.replace(REGEX, MASCARA);

  return cpfFormatado;
}

export function generate(formatted = true, invalid = false) {
    const formatado = formatted;
    const invalido = invalid;
  
    const gerarNum = max => () => Math.floor(Math.random() * (max + 1));
    const criarArrAleatoria = (tamanho, max) => Array.from(Array(tamanho), gerarNum(max));
  
    const digitos = criarArrAleatoria(9, 9);
  
    if (invalido) {
      const pseudoDv = criarArrAleatoria(2, 9);
      const pseudoCpf = digitos.concat(pseudoDv);
      const pseudoCpfEmStr = pseudoCpf.join("");
  
      return formatado
        ? format(pseudoCpfEmStr)
        : pseudoCpfEmStr;
    }
  
    const dv = calcDv(digitos);
    const dvEmStr = dv.join("");
    const digitosEmStr = digitos.join("");
    const cpf = digitosEmStr + dvEmStr;
  
    return formatado
      ? format(cpf)
      : cpf;
}

export function isValid(cpf = "", byLength = false) {
  const porComprimento = byLength;

  const ehStr = typeof cpf === "string";
  if (!ehStr) return false;

  const cpfLimpo = clear(cpf);

  const temComprimentoValido = cpfLimpo.length === 11;
  if (porComprimento || !temComprimentoValido) return temComprimentoValido;

  const [, numero, dv] = cpfLimpo.match(/^(\d{9})(\d{2})$/);

  const dvVerdadeiro = calcDv(numero);
  const dvVerdadeiroEmStr = dvVerdadeiro.join("");

  const cpfEhValido = dvVerdadeiroEmStr === dv;
  return cpfEhValido;
}