import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import FooterMenu from '../FooterMenu';
import * as ClientHelper from '../../helpers/ClientHelper';
import { SubmissionError } from 'redux-form';
import { logoColor } from '../../helpers/colorsHelper';
import DialogUtil from '../DialogUtil';
import Subheader from 'material-ui/Subheader';
import Paper from 'material-ui/Paper';
import FieldReviewBox from '../FieldReviewBox';
import ReviewDivider from '../ReviewDivider';

const paperStyle = {
  height: 'auto',
  width: '100%',
  marginLeft: 15,
  display: 'inline-block',
  padding: '1em',
};

const subheaderStyle = {
  color: logoColor,
};

class ConfirmationStep extends Component {
  handlePrev = () => {
    const { currentStep, setStep } = this.props;
    setStep(currentStep - 1);
  };

  onSubmit = (values) => {
    const clientData = this.props.clientData;
    const token = this.props.token;
    const domain = clientData.domain;
    const email = clientData.admin.email;
    const password = clientData.admin.password;
    const environment = process.env.REACT_APP_API_REDIRECT_ENVIRONMENT;

    let redirectUrl = '';

    if (token === null) {
      redirectUrl = `http://${domain}.${environment}/admin/login?email=${email}&pwd=${password}&domain=${domain}`;
    } else {
      redirectUrl = `http://${domain}.${environment}/admin/login?email=${email}&token=${token}&domain=${domain}`;
    }

    this.props.addRedirectUrl(redirectUrl);

    return ClientHelper.createPurchase(token, clientData)
      .then((result) => {
        this.props.history.push('/redirecting');
      })
      .catch((err) => {
        if (err instanceof SubmissionError) {
          throw err;
        } else {
          this.props.addError(err.message);
        }
      });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    const data = this.props.clientData;
    const plan = this.props.planInfo;

    const voucher = data.payment.voucher;
    const totalDiscount = voucher
      ? voucher.type === 'percentage'
        ? (plan.price / 100) * voucher.discount
        : voucher.discount
      : '';

    const totalPriceWithDiscount = voucher
      ? voucher.type === 'percentage'
        ? plan.price - totalDiscount
        : plan.price - voucher.discount
      : '';

    return (
      <div>
        <Grid style={{ marginTop: '1em' }}>
          <Row center="md">
            <Col md={10}>
              <Row start="md">
                <Col md={6}>
                  <Paper style={paperStyle} zDepth={1}>
                    <Subheader style={subheaderStyle}>
                      Dados cadastrais
                    </Subheader>
                    <FieldReviewBox
                      label="Nome"
                      content={data.admin.fullName}
                    />
                    <FieldReviewBox
                      label="E-mail de acesso"
                      content={data.admin.email}
                    />
                    <FieldReviewBox
                      label="Telefone de contato"
                      content={data.admin.phone}
                    />
                    {data.company.documentType === 'CNPJ' ? (
                      <div>
                        <FieldReviewBox
                          label="Empresa"
                          content={data.company.name}
                        />
                        <FieldReviewBox
                          label="CNPJ"
                          content={data.company.document}
                        />
                        <FieldReviewBox
                          label="Inscrição Municipal"
                          content={data.company.inscricaoMunicipal}
                        />
                        <FieldReviewBox
                          label="Inscrição Estadual"
                          content={
                            data.company.inscricaoEstadual
                              ? data.company.inscricaoEstadual
                              : 'Isento'
                          }
                        />
                      </div>
                    ) : (
                      <FieldReviewBox
                        label="CPF"
                        content={data.company.document}
                      />
                    )}
                    <FieldReviewBox
                      label="Endereço"
                      content={`${data.company.address.street}, ${
                        data.company.address.number
                      } - ${data.company.address.district} - ${
                        data.company.address.city
                      }/${data.company.address.state} ${
                        data.company.address.addressComplement
                          ? data.company.address.addressComplement
                          : ''
                      } - CEP ${data.company.address.zipCode}`}
                    />
                  </Paper>
                </Col>

                <Col md={6}>
                  <Paper style={paperStyle} zDepth={1}>
                    <Subheader style={subheaderStyle}>
                      Informações da contratação
                    </Subheader>
                    <FieldReviewBox
                      label="Domínio"
                      content={`${data.domain}.mambowifi.com`}
                    />
                    <FieldReviewBox
                      label="E-mail financeiro"
                      content={data.payment.financialEmail}
                    />

                    <ReviewDivider />
                    <Subheader style={subheaderStyle}>Meu plano</Subheader>
                    <FieldReviewBox
                      label="Plano"
                      content={`${plan.name} ${
                        plan.name === '1'
                          ? 'ponto de acesso'
                          : 'pontos de acesso'
                      }`}
                    />

                    {voucher ? (
                      <Row left="md">
                        <Col md={4}>
                          <FieldReviewBox
                            label="Valor"
                            content={new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(plan.price)}
                          />
                        </Col>
                        <Col md={4}>
                          <FieldReviewBox
                            label="Desconto (cupom)"
                            content={new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(totalDiscount)}
                          />
                        </Col>
                        <Col md={4}>
                          <FieldReviewBox
                            label="Valor total"
                            content={new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(totalPriceWithDiscount)}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row left="md">
                        <Col md={4}>
                          <FieldReviewBox
                            label="Valor"
                            content={new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(plan.price)}
                          />
                        </Col>
                      </Row>
                    )}

                    <ReviewDivider />
                    <Subheader style={subheaderStyle}>
                      Dados de pagamento
                    </Subheader>

                    <FieldReviewBox
                      label="Forma de pagamento"
                      content={
                        data.payment.method.type === 'creditCard'
                          ? 'Cartão de crédito'
                          : 'Boleto bancário'
                      }
                    />

                    {data.payment.method.type === 'creditCard' && (
                      <Grid style={{ padding: '0' }}>
                        <Row>
                          <Col md={6}>
                            <FieldReviewBox
                              label="Cartão número"
                              content={data.payment.method.creditCardNumber}
                            />
                          </Col>
                          <Col md={6}>
                            <FieldReviewBox
                              label="Titular"
                              content={data.payment.method.creditCardName}
                            />
                          </Col>
                          <Col md={4}>
                            <FieldReviewBox
                              label="Bandeira"
                              content={data.payment.method.creditCardBrand}
                            />
                          </Col>
                          <Col md={4}>
                            <FieldReviewBox
                              label="Vencimento"
                              content={`${data.payment.method.creditCardValidityMonth}/${data.payment.method.creditCardValidityYear}`}
                            />
                          </Col>
                          <Col md={4}>
                            <FieldReviewBox
                              label="CVV"
                              content={data.payment.method.creditSecurityCode}
                            />
                          </Col>
                          <Col md={12}>
                            <FieldReviewBox
                              label="Endereço de faturamento"
                              content={`${data.payment.address.street}, ${
                                data.payment.address.number
                              } - ${data.payment.address.district} - ${
                                data.payment.address.city
                              }/${data.payment.address.state} ${
                                data.payment.address.addressComplement
                                  ? data.payment.address.addressComplement
                                  : ''
                              } - CEP ${data.payment.address.zipCode}`}
                            />
                          </Col>
                        </Row>
                      </Grid>
                    )}
                  </Paper>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <FooterMenu
            label="Confirmar"
            handlePrev={this.handlePrev}
            style={{ marginTop: '2em', marginBottom: '2em' }}
          />

          {submitting === true && (
            <DialogUtil
              modal={false}
              open={submitting}
              text="Aguarde! Estamos validando seus dados e criando sua conta."
            />
          )}
        </form>
      </div>
    );
  }
}

export default ConfirmationStep;
