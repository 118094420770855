import { error } from './errorReducer';
import { plans } from './plansReducer';
import { currentStep } from './stepReducer';
import { loading } from './loadingReducer';
import { numberOfAps } from './numberOfApsReducer';
import { planType } from './planTypeReducer';
import { client } from './clientReducer';
import { payment } from './paymentReducer';
import { termOfUse } from './termOfUseReducer';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

export default combineReducers({
    currentStep,
    error,
    client,
    payment,
    plans,
    numberOfAps,
    planType,
    loading,
    termOfUse,
    form: formReducer
});
