import { ADD_ALL_PLANS } from '../actions/planActions';

export const plans = (state = {}, action) => {
    switch (action.type) {
        case ADD_ALL_PLANS:
            return action
                .plans
                .reduce((reducer, plan) => {
                reducer[plan.name] = plan.plans;
                return reducer;                
            }, {});
        default:
            return state;
    }
}
