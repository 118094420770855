import { connect } from 'react-redux';
import RouteError from '../../components/pages/RouteError';

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteError);