import {
    IS_OPEN_TERM_OF_USE,
    SET_NUMBER_OF_PAGES,
    SET_PAGE_NUMBER,
} from '../actions/termOfUseAction';

const defaultState = {
    numberOfPages: 0,
    pageNumber: 1,
    isOpen: false
};

export const termOfUse = (state = defaultState, action) => {
    switch(action.type) {
        case IS_OPEN_TERM_OF_USE:
            return {
                ...state,
                isOpen: action.isOpen,
            }
        case SET_NUMBER_OF_PAGES:
            return {
                ...state,
                numberOfPages: action.number
            }
        case SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.number
            }
        default:
            return state;
    }
}
