import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import { logoColor, blueBg, white } from '../helpers/colorsHelper';

const colors = {
    buttonBgColor: {
        color: logoColor
    },
    buttonHoverBgColor: {
        color: blueBg
    },
    buttonLabelColor: {
        color: white
    }
}

const Button = (props) => {
    const { label, href, type, style } = props;

    return (
        <FlatButton
          label={label}
          href={href}
          backgroundColor={colors.buttonBgColor.color}
          labelStyle={{
            color: colors.buttonLabelColor.color
          }}
          hoverColor= {colors.buttonHoverBgColor.color}
          type={type}
          style={style}
        />
    );
}
  
export default Button;
