import React, { Component } from 'react';
import {Card, CardActions, CardTitle, CardText} from 'material-ui/Card';
import { grey500, green500, blue500 } from 'material-ui/styles/colors';
import FlatButton from 'material-ui/FlatButton';
import { logoColor, blueBg, white } from '../helpers/colorsHelper';
import FaShoppingCart from 'react-icons/lib/fa/shopping-cart';
import FaGamepad from 'react-icons/lib/fa/gamepad';
import {
	CARD_TYPE_TRIAL,
	CARD_TYPE_PURCHASE,
} from '../helpers/homeHelper';
import styled from 'styled-components';

const Descritpion = styled.p`
	color: ${grey500};
	line-height: 1.7em;
`;

const colors = {
	title: {
		color: logoColor
	},
	subtitle: {
		color: grey500
	},
	buttonBgColor: {
		color: logoColor
	},
	buttonHoverBgColor: {
		color: blueBg
	},
	buttonLabelColor: {
		color: white
	}
}

const trialText = 'Teste grátis por 7 dias e descubra tudo o que a Mambo pode te proporcionar. Com ele você irá gerenciar sua rede Wi-Fi, oferecer login social (Compartilhar e Curtir no Facebook), exibir banners promocionais e criar um banco de dados para realizar ações de comunicação e marketing.';
const purchaseText = 'Conheça nossos planos e encontre o ideal para seu negócio. Comece agora mesmo a gerenciar sua rede com a Mambo, colete informações dos seus clientes, crie campanhas de marketing customizadas e tenha acesso a todas as funcionalidades oferecidas pelo sistema.';

class TrialOrPurchaseCard extends Component {

	render() {
		const { title, subtitle, type, buttonLabel, buttonUrl, onClickRedirect } = this.props;
		return (
			<Card style={{ paddingBottom: '1em' }}>
				<CardTitle
					title={title}
					titleColor={colors.title.color}
					subtitle={subtitle}
					subtitleColor={colors.subtitle.color}
				/>
				<CardText>
					{type === CARD_TYPE_PURCHASE &&
						<div>
							<FaShoppingCart color={green500} size={90} />
							<Descritpion> {purchaseText} </Descritpion>
						</div>
					}
					
					{type === CARD_TYPE_TRIAL && 
						<div>
							<FaGamepad color={blue500} size={90} />
							<Descritpion> {trialText} </Descritpion>
						</div>
					}
				</CardText>
				<CardActions>
					<FlatButton
						label={buttonLabel}
						onClick={() => onClickRedirect(buttonUrl)}
						backgroundColor={colors.buttonBgColor.color}
						hoverColor= {colors.buttonHoverBgColor.color}
						labelStyle={{ color: colors.buttonLabelColor.color }}
					/>
				</CardActions>
			</Card>
		);
	}
}

export default TrialOrPurchaseCard;