import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import DataFormStep from '../../components/steps/DataFormStep';
import { syncValidation } from '../../helpers/validateHelper';
import { addClientType } from '../../actions/clientAction';
import { loadAddress } from '../../actions/addressAction';
import { fetchChangeStepIndex } from '../../actions/stepActions';
import { copyEmailToFinancialEmail } from '../../actions/emailAction';
import { addError } from '../../actions/errorActions';

const selector = formValueSelector('wizard');

const mapStateToProps = (state) => {
    const { currentStep, client, loading } = state;
    const token = state.client.token;
    const {
        isIEIsento,
        isCopyEmail, 
        email, 
        cep,
    } = selector(state, 'isCopyEmail', 'isIEIsento', 'email', 'cep');
    
    return {
        email,
        token,
        currentStep,
        clientType: client.type,
        isIEIsento: isIEIsento ? true : false,
        isCopyEmail: isCopyEmail ? true : false,
        formCep: cep ? cep : null,
        storeCep: client.cep,
        isLoadingAddress: loading.isLoadingAddress,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addClientType: (type) => dispatch(addClientType(type)),
        loadAddress: (cep) => dispatch(loadAddress(cep)),
        setStep: (step) => dispatch(fetchChangeStepIndex(step)),
        copyEmailToFinancialEmail: (email) => dispatch(copyEmailToFinancialEmail(email)),
        addError: (message) => dispatch(addError(message)),
    }
};

const FormComponent = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: syncValidation,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true
})(DataFormStep);

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
