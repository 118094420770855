export const ADD_IS_LOADING_PLANS="ADD_IS_LOADING_PLANS";
export const REMOVE_IS_LOADING_PLANS="REMOVE_IS_LOADING_PLANS";

export const ADD_IS_LOADING_CLIENT_DATA = "ADD_IS_LOADING_CLIENT_DATA";
export const REMOVE_IS_LOADING_CLIENT_DATA = "REMOVE_IS_LOADING_CLIENT_DATA"

export const ADD_IS_LOADING_ADDRESS = 'ADD_IS_LOADING_ADDRESS';
export const REMOVE_IS_LOADING_ADDRESS = 'REMOVE_IS_LOADING_ADDRESS';

export const ADD_IS_LOADING_BILLING_ADDRESS = 'ADD_IS_LOADING_BILLING_ADDRESS';
export const REMOVE_IS_LOADING_BILLING_ADDRESS = 'REMOVE_IS_LOADING_BILLING_ADDRESS';

export const addIsLoadingPlans = () => ({
    type: ADD_IS_LOADING_PLANS,
});

export const removeIsLoadingPlans = () => ({
    type: REMOVE_IS_LOADING_PLANS,
});

export const addIsLoadingClientData = () => ({
    type: ADD_IS_LOADING_CLIENT_DATA,
});

export const removeIsLoadingClientData = () => ({
    type: REMOVE_IS_LOADING_CLIENT_DATA,
});

export const addIsLoadingAddress = () => ({
    type: ADD_IS_LOADING_ADDRESS,
});

export const removeIsLoadingAddress = () => ({
    type: REMOVE_IS_LOADING_ADDRESS,
});

export const addIsLoadingBillingAddress = () => ({
    type: ADD_IS_LOADING_BILLING_ADDRESS,
});

export const removeIsLoadingBillingAddress = () => ({
    type: REMOVE_IS_LOADING_BILLING_ADDRESS,
});
