import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import {
  DomainField,
  CompanyField,
  NameField,
  PhoneField,
  JobAreasField,
  JobTypesField,
  EmailField,
  PasswordField,
} from "../helpers/formInputHelper";
import Button from "../components/Button";
import { TermOfUseField } from '../helpers/formInputHelper';

const TrialForm = ({ openCloseTermOfUse }) => (
  <Grid style={{marginBottom: '20px'}}>
    <Row center="md">
      <Col md={6}>
        <Row start="md">
          <Col md={12}>
            <DomainField />
            <CompanyField label="Nome da Empresa*" hintText="Nome da Empresa" />
            <NameField />
            <PhoneField required />
            <JobAreasField />
            <JobTypesField />
            <EmailField disabled={false} />
            <PasswordField required />
            <TermOfUseField openCloseTermOfUse={openCloseTermOfUse} />
            <Button
              label="CRIAR CONTA"
              type="submit"
              style={{ marginTop: "2em" }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </Grid>
);

export default TrialForm;
