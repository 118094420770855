import { 
    ADD_CLIENT_TYPE,
    ADD_TOKEN, 
    ADD_CLIENT_DATA,
    ADD_CEP,
    ADD_ADDRESS,
    ADD_BILLING_CEP,
    ADD_BILLING_ADDRESS,
    ADD_REDIRECT_URL
} from '../actions/clientAction';

const defaultState = {
    type: null,
    token: null,
    clientData: null,
    cep: null,
    address: null,
    billingCep: null,
    billingAddress: null,
    redirectUrl: null,
};

export const client = (state = defaultState, action) => {
    switch(action.type) {
        case ADD_CLIENT_TYPE:
            return {
                ...state,
                type: action.clientType,
            };
        case ADD_TOKEN:
            return {
                ...state,
                token: action.token ? action.token : null,
            };            
        case ADD_CLIENT_DATA:
            return {
                ...state,
                clientData: action.clientData,
            };
        case ADD_CEP: 
            return {
                ...state,
                cep: action.cep,
            }
        case ADD_ADDRESS:
            return {
                ...state,
                address: action.address,
            };
        case ADD_BILLING_CEP:
            return {
                ...state,
                billingCep: action.cep,
            }
        case ADD_BILLING_ADDRESS:
            return {
                ...state,
                billingAddress: action.address,
            }
        case ADD_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.redirectUrl ? action.redirectUrl : '',
            }
        default:
            return state;
    }
};
